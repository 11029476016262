import axios from "axios";
import { useEffect, useState } from "react";

export default function KürzelListe({ typeListe, setTypeListe, toggleEdit, setCurrTyp }) {

    return (
        <div className="kürzelListe">
            {typeListe.map((type) => (
                <div className="kürzelBox" onClick={() => {
                            setCurrTyp(type);
                            toggleEdit();
                            }}>
                    <div className="hoverBox" >
                        <div className="hoverBoxContent" >
                            {type.Beschreibung}
                        </div>
                            {//<button className="editKürzel" onClick={deleteKürzel}></button>
                            }
                    </div>
                    <div className="kürzelInfoBox">
                        Therapie: {type.Typ}
                    </div>  
                    <div className="kürzelInfoBox">
                        Dauer: {type.Dauer} min
                    </div>
                </div>
            ))}
        </div>
    )

}
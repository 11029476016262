import axios from "axios";
import { useState } from "react";
import "./kürzelStyle.css"

export default function KürzelAdd({toggleAddMode}) {
    
    return (
        <div className="kürzelButtonBox">
            <button className="kürzelButton" onClick={toggleAddMode}>Hinzufügen +</button>
        </div>
    )
}
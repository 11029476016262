import { useEffect, useState } from "react";
import Axios from "axios";

export default function MitarbeiterAdd(props) {
    const [closing, setClosing] = useState(false);
    const [infos, setInfos] = useState({
        Vorname: "",
        Nachname: "",
        Namenszusatz: "",
        Geburtstag: "",
        VersNr: "",
        Krankenkassensitz: "",
        Tel: "",
        Email: "",
        Str: "",
        Hnr: "",
        Ort: "",
        Plz: "",
    });
    const onClose = () => {
        setClosing(true);
       
        setTimeout(() => {
            props.showAdd(infos);
        }, 650);
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setInfos(prevData => ({
          ...prevData,
          [name]: value
        }));
      }

    const handleAdd = () => {
        Axios.post('https://erlomed-kalender.de:3001/api/post/mitarbeiterAdd', infos)
        .then(response => {
            //console.log(response.data);
          })
          .catch(error => {
            console.error(error);
          });
          onClose(true);
        props.updateMitarbeiter(infos);
        
    }

    return (
        <>
            <div className={`blurrB ${closing ? "closing" : ""}`}></div>
            <div className={`mitarbeiterEdit ${closing ? "closing" : ""}`} >
                <div className="mitarbeiterInfoHeader">
                    <h2></h2>
                    <button className="closeButton" onClick={() => onClose(false)}>X</button>
                </div>
                {/*<div className="mitarbeiterRowSeperator" style={{borderColor: "black", border: "2px solid"}}/>*/}
                <div className="mitarbeiterInfoContent">
                    <div className="mitarbeiterInfoKrankenkasse">
                        <h4>Allgemeine Informationen</h4>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">
                                Vorname:
                            </div>
                            <input type="text" name="Vorname" placeholder="Vorname" onChange={handleChange}></input>
                        </div>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">Nachname:</div>
                            <input type="text" name="Nachname" placeholder="Nachname" onChange={handleChange}></input>
                        </div>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">Namenszusatz:</div>
                            <input type="text" name="Namenszusatz" placeholder="Namenszusatz" onChange={handleChange}></input>
                        </div>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">Geburtstag:</div>
                            <input placeholder="Geburtstag" type="text" onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} name="Geburtstag" id="date" onChange={handleChange}></input>
                        </div>

                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">Adresse:</div>
                            <div className="mitarbeiterInfoValue">
                                <input type="text" name="Str" placeholder="Str" onChange={handleChange}></input> <input type="text" name="Hnr" style={{ width: "2rem" }} placeholder="Hnr" onChange={handleChange}></input>,
                                <input type="text" name="Ort" placeholder="Ort" onChange={handleChange}></input> <input type="text" name="Plz" placeholder="Plz" onChange={handleChange}></input></div>
                        </div>
                    </div>
                    <div className="mitarbeiterRowSeperator" />
                    <div className="mitarbeiterInfoKrankenkasse">
                        <h4>Krankenkasse</h4>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">Krankenkassensitz:</div>
                            <input type="text" name="Krankenkassensitz" placeholder="Krankenkassensitz" onChange={handleChange}></input>
                        </div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Versicherungsnummer:</div>
                        <input type="text" name="VersNr" placeholder="VersNr" onChange={handleChange} ></input>
                    </div>
                    <div className="mitarbeiterRowSeperator" />
                    <div className="mitarbeiterInfoKontaktdaten">
                        <h4>Kontaktdaten</h4>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel" >E-mail:</div>
                            <input type="Email" size="50" maxlength="50" required name="Email" placeholder="Email" onChange={handleChange}></input>
                        </div>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">Telefonnummer:</div>
                            <input type="Tel" name="Tel" placeholder="Tel" onChange={handleChange}></input>
                        </div>
                    </div>
                </div>
                <div className="mitarbeiterRowSeperator" />
                {//<div className="mitarbeiterInfoTextArea">

                    //<textarea placeholder="Notizen zum Mitarbeiteren:"></textarea>
                    // </div>
                }
                <div className="mitarbeiterInfoFooter">
                    <button className="mitarbeiterEditSubmit" onClick={handleAdd}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                        </svg>
                        <span>Übernehmen</span>
                    </button>

                </div>
            </div>
        </>
    )
}
import { useEffect, useState } from "react";
import Axios from "axios";

export default function MitarbeiterEdit(props) {
    const [closing, setClosing] = useState(false);
  
    //console.log("Das ist die MitarbeiterID " + props.mitarbeiter.MitarbeiterID);
   
    const [infos, setInfos] = useState(props.mitarbeiter);
    //console.log("Das ist der Mitarbeiter "+ JSON.stringify(infos, null, 2));
    console.log("Changed Status: " + JSON.stringify(infos.Verfügbarkeit, null, 2))
    const onClose = (update) => {
        //console.log("Closing")
        setClosing(true);
        setTimeout(() => {
            props.showEdit(infos);
        }, 650);
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setInfos(prevData => ({
          ...prevData,
          [name]: value
        }));
      }

    const handleSubmit = (event) => {
        //console.log("Neue Daten werden submitted");
        event.preventDefault();
        Axios.post('https://erlomed-kalender.de:3001/api/post/mitarbeiterEdit', infos)
        .then(response => {
            //console.log(response.data);
          })
          .catch(error => {
            console.error(error);
          });
          //console.log("Diese infos werden an den useState übergeben " + JSON.stringify(infos, null, 2))
          onClose(true);
        //console.log("Update")
        props.updateMitarbeiter(infos);
        
    }

    return (
        <>
            <div className={`blurrB ${closing ? "closing" : ""}`}></div>
            <div className={`mitarbeiterEdit ${closing ? "closing" : ""}`} >
                <div className="mitarbeiterInfoHeader">
                    <h2>{props.mitarbeiter.Vorname} {props.mitarbeiter.Nachname}</h2>
                    <button className="closeButton" onClick={() => onClose(false)}>X</button>
                </div>
                {/*<div className="mitarbeiterRowSeperator" style={{borderColor: "black", border: "2px solid"}}/>*/}
                <div className="mitarbeiterInfoContent">
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Vorname:</div>
                        <input type="text" name="Vorname" value={infos.Vorname} onChange={handleChange}></input>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Nachname:</div>
                        <input type="text" name="Nachname" value={infos.Nachname} onChange={handleChange}></input>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Verfügbarkeit:</div>
                        <select className="mitarbeiterInfoSelect" name={"Verfügbarkeit"} value={infos.Verfügbarkeit} onChange={handleChange}>
                            <option value={"Krank"}>Krank</option>
                            <option value={"Verfügbar"}>Verfügbar</option>
                            <option value={"Urlaub"}>Urlaub</option>
                        </select>
                    </div>

                    <div className="mitarbeiterRowSeperator" />
                    <div className="mitarbeiterInfoKrankenkasse">
                        <h4>Praxisinformationen</h4>
                        <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Beruf:</div>
                        <div className="mitarbeiterInfoValue">{infos.Beruf}</div>
                    </div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Standort:</div>
                        <div className="mitarbeiterInfoValue"></div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Stunden:</div>
                        <div className="mitarbeiterInfoValue">{infos.MaxHrs}</div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Urlaubstage:</div>
                        <div className="mitarbeiterInfoValue">{infos.Urlaubstage}</div>
                    </div>
                    <div className="mitarbeiterRowSeperator" />
                    <div className="mitarbeiterInfoKontaktdaten">
                        <h4>Kontaktdaten</h4>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">E-mail:</div>
                            <input type="text" name="Email" value={infos.Email} onChange={handleChange}></input>
                        </div>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">Telefonnummer:</div>
                            <input type="text" name="Tel" value={infos.Tel} onChange={handleChange}></input>
                        </div>
                    </div>
                </div>
                <div className="mitarbeiterRowSeperator" />
                {//<div className="mitarbeiterInfoTextArea">

                    //<textarea placeholder="Notizen zum Mitarbeiteren:"></textarea>
                    // </div>
                }
                <div className="mitarbeiterInfoFooter">
                    <button className="mitarbeiterEditSubmit" onClick={handleSubmit}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                        </svg>
                        <span>Übernehmen</span>
                    </button>

                </div>
            </div>
        </>
    )
}
import { useEffect, useState } from "react"
import axios from "axios"
import KürzelListe from "./KürzelListe"
import KürzelAdd from "./KürzelAdd"
import KürzelAddBox from "./KürzelAddBox"
import KürzelEditBox from "./KürzelEditBox"
import "./kürzelStyle.css"

export default function Kürzel() {
    const [addMode, setAddMode] = useState(false);
    const [typeListe, setTypeListe] = useState([]);
    const [editMode, setEditMode] = useState(false)
    const [currTyp, setCurrTyp] = useState([]);
    useEffect(()=> {
        axios.get("https://erlomed-kalender.de:3001/api/get/terminTypen")
        .then(response=> {
            setTypeListe(response.data)
        }).catch(error => {
            console.error("Fehler beim Laden der Types: " + error);
        })
    }, [currTyp])


    return (
        <div className="kürzelWrapper">
        {addMode && 
        <KürzelAddBox typeListe={typeListe}  setTypeListe={setTypeListe} toggleAddMode={()=>setAddMode(!addMode)}/> 
        }
        {editMode && 
        <KürzelEditBox toggleEditMode={()=>setEditMode(!editMode)} typeListe={typeListe} typ={currTyp} setTypeListe={setTypeListe} /> }
        {(addMode || editMode ) &&
        <div className="blurrKürzel"></div>}
            <div className="kürzelHeader">
            <KürzelAdd toggleAddMode={()=>setAddMode(!addMode)}/>
            </div>
            <KürzelListe toggleEdit={()=>setEditMode(true)} setCurrTyp={setCurrTyp} setTypeListe={setTypeListe} typeListe={typeListe} />
        </div>
    )
}
import Moment from 'moment'
import React, { useState, useEffect, useRef } from "react"
import Axios from 'axios'
import CalendarConfirm from "./CalendarConfirm"
import "./calendarstyle.css"

export default function CalendarEdit(props) {
    const containerRef = useRef(null);
    //console.log("Das kommt alles an: " + JSON.stringify(props, null, 2))
    let zeit = Moment();
    const [patientData, setPatientData] = useState([]);
    const [recipe, setRecipe] = useState([]);
    const [upload, setUpload] = useState(props.termin);
    const [recipeList, setRecipeList] = useState([]);
    const [mitarbeiterList, setMitarbeiterList] = useState([]);
    const [patientenList, setPatientenList] = useState([]);
    const [typList, setTypList] = useState([]);
    const [raumList, setRaumList] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [typIsCostum, setTypIsCostum] = useState(false);
    const [currTyp, setCurrTyp] = useState()
    const [currNote, setCurrNote] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState(patientenList);
    console.log(upload.TerminDauer);
    if (props.termin) {
        zeit = Moment().hour(upload.TerminStunde).minute(upload.TerminSlot);
    }
    const [terminEnd, setTerminEnd] = useState({
        Stunde: zeit.add(upload.TerminDauer, 'minutes').format("H"),
        Minute: zeit.format("mm")
    })

    useEffect(() => {
        //containerRef.current.focus();
        Axios.get('https://erlomed-kalender.de:3001/api/get/terminPatient', {
            params: {
                ID: props.termin.PatientID
            }
        }).then((response) => {
            setPatientData(response.data);
        }).catch(error =>{
            console.error("Fehler beim Aufrufen von Terminpatient " + error)
        })

        Axios.get('https://erlomed-kalender.de:3001/api/get/terminTypen', {
        }).then((response) => {
            setTypList(response.data);
        }).catch(error =>{
            console.error("Fehler beim Aufrufen der Termintypen" + error)
        })

        Axios.get('https://erlomed-kalender.de:3001/api/get/raeume', {
        }).then((response) => {
            setRaumList(response.data);
        }).catch(error =>{
            console.error("Fehler beim Aufrufen der Räume" + error)
        })

        Axios.get('https://erlomed-kalender.de:3001/api/get/patienten', {
            params: {
                cut: true,
            }
        }).then((response) => {
            setPatientenList(response.data);
            setFilteredOptions(response.data);
        }).catch(error =>{
            console.error("Fehler beim Aufrufen der Patienten" + error)
        })

        Axios.get('https://erlomed-kalender.de:3001/api/get/mitarbeiter', {
        }).then((response) => {
            setMitarbeiterList(response.data);
        }).catch(error =>{
            console.error("Fehler beim Aufrufen der Mitarbeiter" + error)
        })

        Axios.get('https://erlomed-kalender.de:3001/api/get/getRecipe', {
            params: {
                ID: upload.PatientID
            }
        }).then((response) => {
            setRecipeList(response.data);
            if(!response.data.filter((rezept) => parseInt(rezept.RezeptID) === parseInt(upload.RezeptID))){
                for(let i = 0; i<typList.length; i++){
                    if(parseInt(typList[i].TypID) === parseInt(response.data[0].Therapie)){
                        setCurrTyp(typList[i].Typ)
                        setUpload(prevData => ({
                            ...prevData,
                            TerminDauer: typList[i].Dauer
                        }))
                    }
                }
            }
        }).catch(error =>{
            console.error("Fehler beim Aufrufen der Rezepte" + error)
        })

        Axios.get('https://erlomed-kalender.de:3001/api/get/terminRecipe', {
            params: {
                ID: props.termin.TerminID
            }
        }).then((response) => {
            setRecipe(response.data);
        }).catch(error =>{
            console.error("Fehler beim Aufrufen des Rezepts" + error)
        })

    }, [upload]);

    const calculateSlots = (TerminStunde, TerminSlot, TerminDauer) => {
        const start = parseInt(TerminStunde) * 60 + parseInt(TerminSlot);
        const end = start + parseInt(TerminDauer);
        let slots = [];
        for (let i = start; i < end; i += 15) {
            slots.push(i);
        }
        return slots;
    };

    
    
    

    const handleSubmit = () => {
        const uploadData = {
            ...upload,
            TerminDauer: typIsCostum
                ? Moment().hour(terminEnd.Stunde).minute(terminEnd.Minute).diff(
                  Moment().hour(upload.TerminStunde).minute(upload.TerminSlot), 'minutes')
                : upload.TerminDauer
        };
          
        if(!props.termin.PatientID){
            
            Axios.post('https://erlomed-kalender.de:3001/api/post/sonderTerminEdit', uploadData)
            .then((response) => {
                props.fetchUpdatedTermine();
                props.toggleEditEdited(uploadData, uploadData.MitarbeiterID, uploadData.TerminStunde, uploadData.TerminSlot)
            })
            .catch(error => {
                console.error("Fehler beim Editieren des Termins: " + error);
            });
        }else{

        Axios.post('https://erlomed-kalender.de:3001/api/post/terminEdit', uploadData)
            .then((response) => {
                props.fetchUpdatedTermine();
                props.toggleEditEdited(uploadData, uploadData.MitarbeiterID, uploadData.TerminStunde, uploadData.TerminSlot)
            })
            .catch(error => {
                console.error("Fehler beim Editieren des Termins: " + error);
            });
        }
    };
    
    const handleDelete = () => {
        if(!props.termin.PatientID){
            Axios.delete('https://erlomed-kalender.de:3001/api/delete/deleteSonderTermin', {
                params: { ID: props.termin.TerminID }
            }).then((response) => {
                props.toggleEditDeleted();
                props.fetchUpdatedTermine();
            }).catch(error => {
                console.error('Fehler beim Löschen des Termins:', error);
            });
        }else{
            Axios.delete('https://erlomed-kalender.de:3001/api/delete/deleteTermin', {
                params: { ID: props.termin.TerminID }
            }).then((response) => {
               
                props.toggleEditDeleted();
                props.fetchUpdatedTermine();
            }).catch(error => {
                console.error('Fehler beim Löschen des Termins:', error);
            });
        }
        
    };
    


    function handleChange(event) {
        const { name, value } = event.target;
        setUpload(prevData => ({
            ...prevData,
            [name]: value
        }));
        if(name==="RezeptID"){
           
            for(let i = 0; i<recipeList.length; i++ ){
                if(parseInt(recipeList[i].RezeptID) === parseInt(value)){
                    setCurrNote(recipeList[i].Notiz);
                    for(let j = 0; j<typList.length; j++){
                        if(parseInt(recipeList[i].Therapie) === parseInt(typList[j].TypID)){
                            setCurrTyp(typList[j]);
                            setUpload(prevData => ({
                                ...prevData,
                                TerminDauer: parseInt(typList[j].Dauer)
                            }))
                            console.log("Changed Typ to: " + typList[j].Dauer)
                            break;
                        }
                    }
                    break;
                }
            }
        }
        if(name === "TerminStunde"){
            if(parseInt(terminEnd.Stunde) < parseInt(value)){
                setTerminEnd(prevData => ({
                    ...prevData,
                    Stunde: parseInt(value)
                }))
            }
        }
        if(name === "TerminSlot"){
            if(parseInt(terminEnd.Minute) <= parseInt(value)){
                setTerminEnd(prevData => ({
                    ...prevData,
                    Minute: (parseInt(value) + 15) % 60
                }))
            }
        }
    }

    function handleTime(event) {
        ////console.log("Aktualisiere TerminEnde: ")
        const {name, value} = event.target;
        setTerminEnd(prevData => ({
            ...prevData,
            [name] : value
        }))
        ////console.log(terminEnd.Stunde, terminEnd.Minute)
    }

  
    const closePrompt = (termin, mitarbeiter, stunde, minute) => {
        setPatientData([]);
        props.toggleEdit(termin, mitarbeiter, stunde, minute);
    }

    

    const toggleConfirm = () => {
        setShowConfirm(!showConfirm)
    }

    if(showConfirm){
        return(
            <CalendarConfirm delete={handleDelete} close={toggleConfirm}></CalendarConfirm>
        )
    }

    const pickColor = (val)=>{
        switch (val) {
            case 'Verfügbar':
                return 'hsl(120, 80%, 50%)';
            case 'Krank':
                return 'hsl(40, 80%, 70%)';
            case 'Urlaub':
                return 'hsl(195, 100%, 72%)';
            default:
                return 'hsl(0, 0%, 100%)';
        }
    }

    const handleSearchInput = (event) => {
        const input = event.target.value;
        setSearchInput(input);
      
        const filtered = patientenList.filter(
          (patient) =>
            `${patient.Vorname} ${patient.Nachname}`.toLowerCase().includes(
              input.toLowerCase()
            )
        );
        setFilteredOptions(filtered);
      
        // Check if there's only one patient in the filtered list
        if (filtered.length === 1) {
          const { PatientID } = filtered[0]; // Get the ID of the patient
          setUpload((prevState) => ({ ...prevState, PatientID })); // Update the PatientID in the upload state
        }
      };

      function renderOptionsHour() {
        let options = []
        let start = parseInt(Moment().hour(upload.TerminStunde).minutes(upload.TerminSlot).add(15, "minutes").format("HH"));
        for (let i = start; i <= 18; i++) {
            options.push(<option key={i} value={i}>{(i < 10 ? "0" : "")}{i}</option>)
        }
        return options;
    }

    function renderOptionsMinute() {
        let options = [];
        let start = (parseInt(upload.TerminStunde) !== parseInt(terminEnd.Stunde) ? 0 : parseInt(Moment().hour(upload.TerminStunde).minutes(upload.TerminSlot).add(15, "minutes").format("mm")));
        for (let i = start; i <= 45; i += 15) {
            options.push(<option value={i} key={i}>{i === 0 ? "00" : i}</option>);
        }

        return options;
    };

    const handleKeyPress = (event) => {
        //console.log("Fire")
        if (event.key === 'Enter') {
          handleSubmit();
        } else if (event.key === 'Escape') {
          closePrompt(props.termin, props.mitarbeiter, props.currentStunde, props.currentSlot);
        }
      };

      const getDescriptionAndType = (recipe) => {
        const filteredTypes = typList.filter(typ => parseInt(recipe.Therapie) === parseInt(typ.TypID));
        if (filteredTypes.length > 0) {
            const { Beschreibung, Typ } = filteredTypes[0];
            return `${Beschreibung}, ${Typ}`;
        }
        return recipe.Therapie;
    };
    

    return (
        <div className="promptBox"  tabIndex={0} onKeyDown={handleKeyPress} ref={containerRef}>
            <div className="promptTop">
                <h1>Termin bearbeiten:</h1>
                <div className="promptButtonWrapper" >
                    <button className='deleteTermin changePrompt' onClick={toggleConfirm}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                    </button>
                    <button className="submitPrompt" style={{ fontWeight: "570" }} onClick={handleSubmit}>✓</button>
                    <button className="closePrompt" onClick={() => closePrompt(props.termin, props.mitarbeiter, props.currentStunde, props.currentSlot)}>X</button>
                </div>
            </div>
            <div className="promptContent" style={{height: "100%"}}>
                <div className="promptRow">
                    <div className="patientInfoLabel">Status</div>
                    <div className={`patientInfoValue`} >
                        <select value={upload.Status} name="Status" onChange={handleChange} >
                            <option value="Abgeschlossen" style={{ color: "hsl(120, 60%, 50%)" }}>Abgeschlossen</option>
                            <option value="Unentschuldigt" style={{ color: "hsl(351, 100%, 50%)" }}>Unentschuldigt</option>
                            <option value="Fahrt" style={{ color: "hsl(195, 100%, 50%)" }}>Fahrt</option>
                            <option value="Krank" style={{ color: "hsl(54, 90%, 70%)" }}>Krank</option>
                            <option value="Urlaub" style={{ color: "hsl(309, 100%, 50%)" }}>Urlaub</option>
                            <option value="Eingeplant" style={{ color: "hsl(0, 0%, 50%)" }}>Eingeplant</option>
                            <option value="Pause" style={{ color: "hsl(272, 100%, 50%)" }}>Pause</option>
                            <option value="Hausbesuch" style={{ color: "hsl(30, 100%, 50%)" }}>Hausbesuch</option>
                        </select>
                    </div>
                </div>
                <div className="promptRow">
                    <div className="patientInfoLabel">Zeit selbst auswählen:</div>
                    <div className="patientInfoValue">
                        <input type='checkbox' onClick={()=>setTypIsCostum(!typIsCostum)}></input>
                    </div>
                </div>
                <div className="promptRow">
                    <div className="patientInfoLabel">Zeit:</div>
                    <div className="patientInfoValue promptInfoTime">
                    <select value={upload.TerminStunde} name="TerminStunde" onChange={handleChange} style={{ marginRight: "-10px" }}>
                            {Array.from({ length: 12 }, (_, index) => {
                                const hour = index + 7;
                                return <option key={hour} value={hour}>{hour < 10 ? "0" : ""}{hour}</option>;
                            })}
                        </select>
                        <select value={upload.TerminSlot} name="TerminSlot" onChange={handleChange}>
                            <option value="0">00</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                        </select>
                        -
                        {typIsCostum ? (
                            <>
                                <select name="Stunde" style={{ marginRight: "-10px" }} value={terminEnd.Stunde} onChange={handleTime} >
                                    {renderOptionsHour()}
                                </select>
                                <select name="Minute" onChange={handleTime} value={terminEnd.Minute}>
                                    {renderOptionsMinute()}
                                </select>
                            </>
                        ) : (
                            <>
                            <select name="Stunde fixedTerminEndStunde" value={upload.TypID} >
                            <option value={upload.TypID}>{(parseInt(Moment().hour(upload.TerminStunde).minute(upload.TerminSlot).add(upload.TerminDauer, "minutes").format("HH")))<10 ? "0" : ""}{parseInt(Moment().hour(upload.TerminStunde).minute(upload.TerminSlot).add(upload.TerminDauer, "minutes").format("HH"))}</option>
                            </select>
                            <select name="Minute fixedTerminEndMinute"  value={upload.TypID} >
                            <option value={upload.TypID}>{(parseInt(Moment().hour(upload.TerminStunde).minute(upload.TerminSlot).add(upload.TerminDauer, "minutes").format("mm")))===0 ? "0" : ""}{parseInt(Moment().hour(upload.TerminStunde).minute(upload.TerminSlot).add(upload.TerminDauer, "minutes").format("mm"))}</option>
                            </select>
                            </>
                        )}
                        <input type="text" placeholder={Moment(props.termin.TerminTag).format("dddd, DD.MM.yyyy")} onFocus={(e) => (e.target.type = "date")} name="TerminTag" id="date" onChange={handleChange}></input>
                    </div>
                </div>
                {(upload.Typ != "HB" && !props.termin.TypID) ? (<div className="promptRow">
                    <div className="patientInfoLabel">Raum:</div>
                    <div className="patientInfoValue">
                        <select name="RaumID" onChange={handleChange} value={upload.RaumID}>
                            {raumList.map((raum) => (
                                <option key={raum.RaumID} value={raum.RaumID}>{raum.RaumID}</option>
                            ))}
                        </select>
                    </div>
                </div>) : null}
                <div className="patientRowSeperator"></div>
                <div className="promptRow">
                    <div className="patientInfoLabel">Mitarbeiter:</div>
                    <div className="patientInfoValue">
                        <select name="MitarbeiterID" onChange={handleChange} value={upload.MitarbeiterID}>
                            {mitarbeiterList.map((mitarbeiter) => (
                                <option style={{color: pickColor(mitarbeiter.Verfügbarkeit)}} key={mitarbeiter.MitarbeiterID} value={mitarbeiter.MitarbeiterID}>{mitarbeiter.Vorname} {mitarbeiter.Nachname}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {(!props.termin.TypID) ? (
                    <div className="promptRow">
                    <div className="patientInfoLabel">Patient:</div>
                    <div className="patientInfoValue">
                        <select name="PatientID" onChange={handleChange} value={upload.PatientID}>
                            {filteredOptions.map((patient) => (
                                <option key={patient.PatientID} value={patient.PatientID}>{patient.Vorname} {patient.Nachname}</option>
                            ))}
                        </select>
                        <input className="searchPatient" placeholder="Suche" type="text" value={searchInput} onChange={handleSearchInput} />
                    </div>
                </div>
                ) : null}
                
                <div className="patientRowSeperator" />
                <div className="promptRow">
                 {(!props.termin.PatientID) ? (
            <div>
                <div className="patientInfoLabel">Rezept:</div>
                <div className="patientInfoValue">
                    <select name="RezeptID" onChange={handleChange} value={upload.RezeptID}>
                        {recipeList.map((recipe) => (
                            <option key={recipe.RezeptID} value={recipe.RezeptID}>
                                {recipe.Eintragung}, {getDescriptionAndType(recipe)}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        ) : null}
    
                    
                </div>
                <div className="promptRow" style={{ justifyContent: "left" }}>
                    <div className="patientInfoLabel" style={{ width: "20%" }}>Notiz:</div>
                    <div className="patientInfoValue">
                        {currNote ? currNote : []}
                    </div>
                </div>
                <div className="patientRowSeperator" />
                <div className="promptRow" style={{ justifyContent: "left" }}>
                    <div className="patientInfoLabel" style={{ width: "20%" }}>Vorher:</div>
                    <div className="promptInfoText">
                        <textarea name="vorher" onChange={handleChange}></textarea>
                    </div>
                </div>
                <div className="promptRow" style={{ justifyContent: "left" }}>
                    <div className="patientInfoLabel" style={{ width: "20%" }}>Nachher:</div>
                    <div className="promptInfoText">
                        <textarea name="nachher" onChange={handleChange}></textarea>
                    </div>
                </div>  
            </div>
        </div>
    )
}
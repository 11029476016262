import Home from "./components/home/Home";
import Navbar from "./components/navbar/Navbar";
import Location from "./components/kalender/Location";
import Patient from "./components/patient/Patient";
import Calendar from "./components/kalender/Calendar";
import Nachrichten from "./components/rezepte/Nachrichten";
import "./style.css"
import "./components/kalender/calendarPrint.css"
import {Route, Routes} from "react-router-dom";
import Axios from "axios";
import { useEffect, useState } from "react";
import SignInSide from "./components/Login/SignInSide";
import Register from "./components/Login/Register";
import Mitarbeiter from "./components/mitarbeiter/Mitarbeiter";
import Profil from "./components/Profil/Profil";
import Kürzel from "./components/Kürzel/Kürzel";

function App() {
  Axios.defaults.withCredentials = true;
 const [isLogged, setIsLogged] = useState(false);
const [isLoading, setIsLoading] = useState(true)
const [isAdmin, setIsAdmin] = useState(true);
const [location, setLocation] = useState([]);
useEffect(() => {
    // Check for a valid cookie here
    const accessToken = localStorage.getItem('access-token');

    if (accessToken) {
      // Perform a request to the server to validate the token
      Axios.get('https://erlomed-kalender.de:3001/api/auth/checkToken')
        .then(response => {
          // Token is valid, user is logged in
          const auth = response.data.valid
          setIsLogged(auth);
          setIsLoading(false);
        })
        .catch(error => {
          // Token is invalid or expired, user is not logged in
          setIsLogged(false);
          setIsLoading(false);
        });
        Axios.get('https://erlomed-kalender.de:3001/api/auth/getCurrUser')
        .then(response =>{
          setLocation(response.data.Standort)
          ////console.log("Die Location die gegetted wird: " + response.data.Standort)
          if(response.data.isAdmin === 1){
            setIsAdmin(true);
          }else{
            setIsAdmin(false);
          }
        })
        .catch(error =>{
          setIsAdmin(false);
          console.error(error);
        })
    } else {
      // Token doesn't exist, user is not logged in
      setIsLogged(false);
      setIsLoading(false);
    }
  }, []);

 if(isLogged && !isLoading){
  return(
    <>
      <Navbar logout={()=>setIsLogged(false)}/>
      <div className="container">
        <Routes>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/calendar" element={<Calendar setIsLogged={setIsLogged} locationID={location}/>}></Route>
          <Route path="/patient" element={<Patient />}></Route>
          {isAdmin && <Route path="/mitarbeiter" element={<Mitarbeiter />}></Route>}
          {isAdmin && <Route path="/kürzel" element={<Kürzel />}></Route>}
          <Route path="/profil" element={<Profil />}></Route>
          <Route path='*' element={<Home />}/>
        </Routes>
      </div>
      
    </>
   
    
  )
 }else if(!isLogged && !isLoading){
  return(
    <>
       <Routes>
          <Route path="/login" element={<SignInSide logged={() => setIsLogged(true)}/>}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path='*' element={<SignInSide logged={() => setIsLogged(true)} />}/>
        </Routes>
        </>
  )
 }
  
  
}

export default App;
import "./rezeptStyle.css";
import Axios from 'axios';
import React, { useState, useRef, useEffect } from "react";

export default function RezepEdit(props) {
    const [typList, setTypList] = useState(props.typList);
    const [searchInput, setSearchInput] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(props.typList);
    const [upload, setUpload] = useState({
        RezeptID: props.rezept.RezeptID,
        Eintragung: props.rezept.Eintragung,
        Ausstellung: props.rezept.Ausstellung,
        Notiz: props.rezept.Notiz,
        Einheiten: props.rezept.Einheiten,
        Therapie: props.rezept.Therapie
    })

    /*useEffect(() => {
        Axios.get("https://erlomed-kalender.de:3001/api/get/terminTypen", {}
        ).then((response) => {
            setTypList(response.data);
            setFilteredOptions(response.data);
        }).catch((error) => {
            console.error("Fehler beim aufrufen der Typen: " + error);
        })
    })*/

    const handleSubmit = () => {
        Axios.post('https://erlomed-kalender.de:3001/api/post/rezeptEdit', upload)
            .then(response => {
                //console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
            const updatedList = props.recipeList.map(recipe => {
                if (recipe.RezeptID === upload.RezeptID) {
                  return upload; // Aktualisiere das Rezept, wenn RezeptID übereinstimmt
                }
                return recipe; // Andernfalls bleibt das Rezept unverändert
              });
        props.updateRecipeList(updatedList);
        //props.setTyp(upload.Therapie);
        props.onClose();
    }

    const handleDelete = ()=> {
        Axios.delete('https://erlomed-kalender.de:3001/api/delete/rezept', {
            params : {
                RezeptID : props.rezept.RezeptID,
                PatientID: props.rezept.PatientID
            }
        }).then(response => {
            console.log("Erfolgreich gelöscht")
        }).catch(error => {
            console.error("Fehler beim löschen des Rezepts")
        })
        props.updateRecipeList(props.recipeList.filter( recipe => recipe.RezeptID !== props.rezept.RezeptID));
        props.onClose();
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setUpload(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            formData.append('RezeptID', upload.RezeptID);
            try {
                await Axios.post('https://erlomed-kalender.de:3001/api/post/image', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // File uploaded successfully
                //console.log('Image uploaded');
            } catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleSearchInput = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = typList.filter(
            (typ) =>
                `${typ.Typ}`.toLowerCase().includes(
                    input.toLowerCase()
                )
        );
        setFilteredOptions(filtered);

        // Check if there's only one patient in the filtered list
        if (filtered.length === 1) {
            const { TypID } = filtered[0]; // Get the ID of the patient
            setUpload((prevState) => ({ ...prevState, Therapie: TypID })); // Update the PatientID in the upload state
        }
    };

    return (
        <div className="rezeptAdd">
            <div className="rezeptAddInfoHeader">
                <h2>Rezept: {props.rezept.RezeptID}</h2>
                <div className="rezeptAddButtonWrapper">
                    <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChange} />
                    <button className="printButton" onClick={handleButtonClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                        </svg>

                    </button>
                    <button className="printButton" onClick={handleDelete}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </button>
                    <button className="closeButton" onClick={() => props.onClose()}>X</button>

                </div>
            </div>
            <div className="patientInfoRow">
                <div className="patientInfoLabel">
                    Eintragungsdatum:
                </div>
                <input type="text" value={upload.Eintragung} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} name="Eintragung" id="date" onChange={handleChange}></input>
            </div>
            <div className="patientInfoRow">
                <div className="patientInfoLabel">
                    Ausstellungsdatum: </div>
                <input type="text" value={upload.Ausstellung} onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} name="Ausstellung" id="date" onChange={handleChange}></input>
            </div>
            <div className="patientInfoRow">
                <div className="patientInfoLabel">Einheiten: </div>
                <input value={upload.Einheiten} type="number" name="Einheiten" onChange={handleChange}></input>
            </div>
            <div className="patientInfoRow">
                <div className="patientInfoLabel">Therapie Typ: </div>
                <div>
                    <input className="searchRezept" placeholder="Suche" type="text" value={searchInput} onChange={handleSearchInput} />
                    <select name="Therapie" style={{ padding: "4px" }} onChange={handleChange} value={upload.Therapie}>
                        {filteredOptions.map((typ) => (
                            <option key={typ.TypID} value={typ.TypID}>
                                {typ.Typ} {typ.Dauer}
                            </option>
                        ))}
                    </select>
                </div>

            </div>
            <div className="patientRowSeperator" />
            <div className="patientInfoRow" style={{ width: "100%", height: "100%" }}>
                <div className="patientInfoLabel" >Notiz:</div>
                <div className="patientInfoValue" style={{ width: "100%", height: "100%" }}>
                    <textarea value={upload.Notiz} placeholder={upload.Notiz} name="Notiz" onChange={handleChange}></textarea>

                </div>
            </div>
            <div className="patientInfoFooter">
                <button className="patientEditSubmit" style={{ backgroundColor: "#F2F3F5", borderColor: "#F2F3F5" }} onClick={handleSubmit}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                        <path fill-rule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clip-rule="evenodd" />
                    </svg>
                    <span>Übernehmen</span>
                </button>

            </div>

        </div>
    )

}
import "./calendarstyle.css"
import "./calendarPrint.css"
import CalendarNav from "./CalendarNav"
import React, { useState, useRef, useEffect } from "react"
import { useLocation } from 'react-router-dom'
import Moment from 'moment';
import 'moment/locale/de';
import CalendarBox from "./CalendarBox"
import CalendarSidebar from "./CalendarSidebar"
import ReactToPrint from 'react-to-print';
import PrintButton from './PrintButton';
import axios from "axios";

export default function Calendar({setIsLogged, locationID}) {
 // //console.log("Mounted")
  ////console.log("Die Location die an Kalender übergeben wird: " + locationID)
    Moment.locale("de"); 
    const [currentWeek, setCurrentWeek] = useState(Moment());
    const [location, setLocation] = useState(locationID);
    const [typList, setTypList] = useState([]);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const calendarWrapperRef = useRef();
    const button = <ReactToPrint
    trigger={() => <PrintButton />} 
    content={() => calendarWrapperRef.current}
  />
    useEffect(() => {
        // Check for a valid cookie here
        const accessToken = localStorage.getItem('access-token');
    
        if (accessToken) {
          // Perform a request to the server to validate the token
          axios.get('https://erlomed-kalender.de:3001/api/auth/checkToken')
            .then(response => {
              // Token is valid, user is logged in
              const auth = response.data.valid
              setIsLogged(auth);
              console.log("Check Token")
            })
            .catch(error => {
              // Token is invalid or expired, user is not logged in
              setIsLogged(false);
              console.log("Check Token Failed")
            });
        } else {
          // Token doesn't exist, user is not logged in
          setIsLogged(false);
        }
        axios.get('https://erlomed-kalender.de:3001/api/get/terminTypen', {
        }).then((response) => {
            setTypList(response.data);
            setLoadingFlag(true);
        }).catch(error =>{
          console.log("Fehler beim Aufrufen der Typliste")
            setLoadingFlag(false)
        })
      }, []);
    return (
        <div className="calendarContainer">
            <CalendarNav button={button} setCurrentWeek={setCurrentWeek} praxis={location} now={currentWeek} changeLocation={setLocation}/>
            <div ref={calendarWrapperRef} className="topDiv">
          
            <CalendarWrapper typListe={typList} praxis={location} setCurrentWeek={setCurrentWeek} now={currentWeek} />
            
            </div>
        </div>
    )
}

function CalendarWrapper(props) {
    return (
        <div className="calendarWrapper">
            <CalendarSidebar currentWeek={props.now} setCurrentWeek={props.setCurrentWeek} />
            <CalendarBox typListe={props.typListe}  praxis={props.praxis} currentPraxis={props.praxis} currentWeek={props.now} />
        </div>
    )
}


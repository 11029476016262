import "./rezeptStyle.css";
import Axios from "axios";
import React, { useState, useEffect } from "react";

export default function RezeptAdd(props) {
  console.log("Mounted with : " + JSON.stringify(props.recipeList, null, 2))
  const [typList, setTypList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(typList);
  const [upload, setUpload] = useState({
    Eintragung: "",
    Ausstellung: "",
    Notiz: "",
    Einheiten: "",
    Therapie: "",
    PatientID: props.patient.PatientID,
  });
  const handleSubmit = () => {
    let responseID = 0;
    Axios.post("https://erlomed-kalender.de:3001/api/post/rezeptAdd", upload)
      .then((response) => {
        //console.log(response.data);
        console.log("Recieving: " + JSON.stringify(response.data, null,2))
        responseID = response.data[0];
      })
      .catch((error) => {
        console.error(error);
      });
    
    const newRecipe = {
        RezeptID: responseID,
      Eintragung: upload.Eintragung,
      Ausstellung: upload.Ausstellung,
      Notiz: upload.Notiz,
      Einheiten: upload.Einheiten ,
      Therapie: upload.Therapie,
      PatientID: upload.PatientID,
    };
    console.log("What I pass: " + JSON.stringify(newRecipe, null, 2));
    props.updateRecipeList([...props.recipeList, newRecipe]);
    props.onClose();
  };

  useEffect(() => {
    Axios.get("https://erlomed-kalender.de:3001/api/get/terminTypenNonSonder")
      .then((response) => {
        if (response.data[0]) {
          //console.log(JSON.stringify(response.data, null, 2))
          setTypList(response.data);
          setFilteredOptions(response.data);
          setUpload((prevData) => ({
            ...prevData,
            Therapie: response.data[0].TypID,
          }));
        }
      })
      .catch((error) => {
        //console.log("Fehler beim Aurufen der Typen: " + error)
      });
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    //console.log("Name und value: "+ name + " " + value)
    setUpload((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const print = () => {};

  const handleSearchInput = (event) => {
    const input = event.target.value;
    setSearchInput(input);

    const filtered = typList.filter((typ) =>
      `${typ.Typ}`.toLowerCase().includes(input.toLowerCase())
    );
    setFilteredOptions(filtered);

    // Check if there's only one patient in the filtered list
    if (filtered.length === 1) {
      const { TypID } = filtered[0]; // Get the ID of the patient
      setUpload((prevState) => ({ ...prevState, Therapie: TypID })); // Update the PatientID in the upload state
    }
  };

  return (
    <div className="rezeptAdd">
      <div className="rezeptAddInfoHeader">
        <h2>
          Neues Rezept für {props.patient.Vorname} {props.patient.Nachname}
        </h2>
        <div className="rezeptAddButtonWrapper">
          <button className="printButton" onClick={() => print()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z"
              />
            </svg>
          </button>
          <button className="closeButton" onClick={() => props.onClose()}>
            X
          </button>
        </div>
      </div>
      <div className="patientInfoRow">
        <div className="patientInfoLabel">Eintragungsdatum:</div>
        <input
          type="text"
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          name="Eintragung"
          id="date"
          onChange={handleChange}
        ></input>
      </div>
      <div className="patientInfoRow">
        <div className="patientInfoLabel">Ausstellungsdatum: </div>
        <input
          type="text"
          onFocus={(e) => (e.target.type = "date")}
          onBlur={(e) => (e.target.type = "text")}
          name="Ausstellung"
          id="date"
          onChange={handleChange}
        ></input>
      </div>
      <div className="patientInfoRow">
        <div className="patientInfoLabel">Einheiten: </div>
        <input type="number" name="Einheiten" onChange={handleChange}></input>
      </div>
      <div className="patientInfoRow">
        <div className="patientInfoLabel">Therapie Typ: </div>
        <div className="patientInfoTherapieSelect">
          <select
            name="Therapie"
            value={upload.Therapie}
            onChange={handleChange}
          >
            {filteredOptions.map((typ) => (
              <option key={typ.TypID} value={typ.TypID}>
                {typ.Typ} {typ.Dauer}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="patientRowSeperator" />
      <div className="patientInfoRow" style={{ width: "100%", height: "100%" }}>
        <div className="patientInfoLabel">Notiz:</div>
        <div
          className="patientInfoValue"
          style={{ width: "100%", height: "100%" }}
        >
          <textarea name="Notiz" onChange={handleChange}></textarea>
        </div>
      </div>
      <div className="patientInfoFooter">
        <button
          className="patientEditSubmit"
          style={{ backgroundColor: "#F2F3F5", borderColor: "#F2F3F5" }}
          onClick={handleSubmit}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="w-5 h-5"
          >
            <path
              fill-rule="evenodd"
              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Übernehmen</span>
        </button>
      </div>
    </div>
  );
}

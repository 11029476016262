import "./profilStyle.css"
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import ProfilInfo from "./ProfilInfo"
import ProfilEdit from "./ProfilEdit";

export default function Profil() {
    const [profil, setProfil] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [standort, setStandort] = useState([])
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await Axios.get('https://erlomed-kalender.de:3001/api/auth/getCurrUser');
            const varProfil = response.data;
            setProfil(varProfil);
      
            const locationResponse = await Axios.get('https://erlomed-kalender.de:3001/api/get/getLocation', {
              params: {
                ID: varProfil && varProfil.Standort
              }
            });
            const locationData = locationResponse.data[0];
            setStandort(locationData);
          } catch (error) {
            console.error(error);
          }
        };
        fetchData();
      }, []);
        
      function handleEditToggle(input){
        setEditMode(input)
      }

      function submit(uploadData) {
        Axios.post("https://erlomed-kalender.de:3001/api/post/profilEdit", uploadData)
            .then(response => {
            
            })
            .catch(error => {
                console.error(error);
            })
            setProfil(uploadData);
    }
    
    return (
        <div className="profilWrapper">
            {!editMode && <ProfilInfo profil={profil} standort={standort} toggle={handleEditToggle} widget={false}/>}
            {editMode && <ProfilEdit profil={profil} standort={standort} toggle={handleEditToggle}  parentSubmit={submit}/>}
        </div>
    )
}
import Moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import "./calendarstyle.css";

export default function CalendarAdd({
  praxis,
  tag,
  stunde,
  minute,
  mitarbeiter,
  toggleAdd,
  toggleShow,
  typListe,
  setTerminListe,
  terminListe,
  fetchUpdatedTermine,
}) {
  const containerRef = useRef(null);
  const [patientData, setPatientData] = useState([]);
  //Wichtig, falls Termine mehrmals zur selben Zeit belegt werden sollen
  const [anzahl, setAnzahl] = useState(0);
  //Liste der Rezepte des jeweiigen Patientens
  const [recipeList, setRecipeList] = useState([]);
  //Liste der verfügbaren Mitarbeiter am jeweiligen Tag.
  const [mitarbeiterList, setMitarbeiterList] = useState([]);
  //Liste der Patienten
  const [patientenList, setPatientenList] = useState([]);
  //Bool für Termine, dessen Zeit manuell ausgesucht wird
  const [typIsCostum, setTypIsCostum] = useState(false);
  //Bool für patientenlose Termine
  const [typOhnePatient, setTypOhnePatient] = useState(false);
  //Sonderterminliste
  const [sonderTerminListe, setSonderTerminListe] = useState([])
  //
  const [upload, setUpload] = useState({
    MitarbeiterID: mitarbeiter.MitarbeiterID,
    PatientID: "",
    RaumID: "",
    RezeptID: "",
    TerminTag: Moment(tag, "dddd, DD.MM.YYYY"),
    TerminStunde: stunde,
    TerminSlot: minute,
    TerminDauer: 15,
    Status: "Eingeplant",
    vorher: "",
    nachher: "",
    Standort: praxis,
  });
  //Aktuell ausgewählter Typ
  const [currTyp, setCurrTyp] = useState("");
  //Hilfs-State für Berechnung der Termindauer. Siehe function: handleChange und handleTime
  const [terminEnd, setTerminEnd] = useState({
    Stunde: upload.TerminStunde,
    Minute: upload.TerminSlot,
  });
  //Liste der verfügbaren Räume
  const [raumList, setRaumList] = useState([]);
  //Liste der Terminarten
  const [typList, setTypList] = useState(typListe);
  //Searchbar
  //Input in Searchbar
  const [searchInput, setSearchInput] = useState("");
  //Gefilterte Liste anhand input. Siehe: handleFilteredOptions
  const [filteredOptions, setFilteredOptions] = useState(patientenList);
  //Variable um alte Rezepte zu markieren
  const [IsOld, setIsOld] = useState(false);
  //Aktuell angezeigte Notiz. Wird beim Ändern des Rezepts mitgeändert.
  const [currNote, setCurrNote] = useState();




  useEffect(() => {
    //containerRef.current.focus();

    Axios.get("https://erlomed-kalender.de:3001/api/get/raeume", {}).then((response) => {
      setRaumList(response.data);
    });

    Axios.get("https://erlomed-kalender.de:3001/api/get/patienten", {
      params: {
        cut: true,
      },
    }).then((response) => {
      if (response.data[0]) {
        setPatientenList(response.data);
        setFilteredOptions(response.data);
        if (upload.PatientID === "") {
          setUpload((prevData) => ({
            ...prevData,
            PatientID: response.data[0].PatientID,
          }));
        }
      }
    });

    Axios.get("https://erlomed-kalender.de:3001/api/get/mitarbeiter", {
      params: {
        Praxis: praxis,
      },
    })
      .then((response) => {
        if (response.data[0]) {
          setMitarbeiterList(response.data);
          if (!upload.MitarbeiterID) {
            console.log("Mitarbeiter is leer : " + upload.MitarbeiterID);
            setUpload((prevData) => ({
              ...prevData,
              MitarbeiterID: response.data[0].MitarbeiterID,
            }));
          }
        } else {
          setMitarbeiterList([]);
        }
      })
      .catch((error) => {
        console.error("Fehler beim Abrufen der Mitarbeiter: " + error);
      });

    Axios.get("https://erlomed-kalender.de:3001/api/get/getRecipe", {
      params: {
        ID: upload.PatientID,
      },
    }).then((response) => {
      if (response.data[0]) {
        setRecipeList(response.data);
        //Wenn die Liste nicht leer ist und kein Rezept bereits ausgewählt ist, dann wähle das erste Rezept aus der Liste aus
        if (
          response.data.filter(
            (rezept) => rezept.RezeptID === parseInt(upload.RezeptID)
          ).length === 0 &&
          response.data.length !== 0
        ) {
          let typ = checkIsOld(response.data[0].Therapie)
          setCurrTyp(typ);
          setUpload((prevData) => ({
            ...prevData,
            RezeptID: response.data[0].RezeptID,
            TerminDauer: typ.TypID === "Dummy" ? 0 : typ.Dauer,
          }));
          setCurrNote(response.data[0].Notiz);
        }
      }
    });

    ////console.log("trigger")
  }, [upload.PatientID, upload.RezeptID, currTyp, upload.TerminDauer]);

  

const handleSubmit = () => {
  if(typOhnePatient){
    const uploadData = {
      ...upload,
      times: anzahl-1,
  };
  if(anzahl > 0){

  Axios.post("https://erlomed-kalender.de:3001/api/post/sonderTerminAddMultiple", uploadData)
  .then((response) => {
    fetchUpdatedTermine();
    toggleAdd([]);
  }).catch((error) => {
    console.error(error);
  });
  } else {
    Axios.post("https://erlomed-kalender.de:3001/api/post/sonderTermin", uploadData)
    .then((response) => {
      fetchUpdatedTermine();
      toggleAdd([]);
    })
    .catch((error) => {
      console.error("Error:", error.response ? error.response.data : error.message);
    });
  }
  } else{
    const uploadData = {
      ...upload,
      times: anzahl-1,
  };

  if (anzahl > 0) {
      Axios.post("https://erlomed-kalender.de:3001/api/post/terminAddMultiple", uploadData)
          .then((response) => {
              fetchUpdatedTermine();
              toggleAdd([]);
          }).catch((error) => {
              console.error(error);
          });
  } else {
    Axios.post("https://erlomed-kalender.de:3001/api/post/terminAdd", uploadData)
    .then((response) => {
      fetchUpdatedTermine();
      toggleAdd([]);
    })
    .catch((error) => {
      console.error("Error:", error.response ? error.response.data : error.message);
    });
  
  }
  }
    
    
};

  function checkIsOld(recipeTherapyType) {
    const dummyType = {
      TypID: "Dummy",
      Typ: 0,
      Dauer: 0,
      Beschreibung: 0,
    }
    console.log(typList.filter(
      (typ) => typ.TypID === parseInt(recipeTherapyType, 10)
    ).length === 0)
    //Search through list of tyes for type with passed recipe type
    let typ = (typList.filter(
      (typ) => typ.TypID === parseInt(recipeTherapyType, 10)
    ).length === 0)
      ? dummyType //If there is no type with the given recipe Type assign NaN
      : typList.filter(
        (typ) => typ.TypID === parseInt(recipeTherapyType, 10) //Otherwise assign the type id
      )[0]

    if (typ.TypID === "Dummy") {
      //console.log("Check type result: isOld")
      setIsOld(true)
    } else {
      setIsOld(false);
    }
    return typ
  }


  function handleChange(event) {
    const { name, value } = event.target;
    setUpload((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if(name === "TypID" && typOhnePatient){
      console.log("Fire");
      typList.filter(typ => parseInt(typ.TypID) === parseInt(value)).map(typ => {
        setUpload((prevData) => ({
          ...prevData,
          TerminDauer: parseInt(typ.Dauer, 10),
        }));

      })
      }
      
    if (name === "RezeptID") {
      //Iterate through all recipes
      for (let i = 0; i < recipeList.length; i++) {
        //For the recipe newly selected...
        if (parseInt(recipeList[i].RezeptID) === parseInt(value)) {
          //...update current Note and...
          setCurrNote(recipeList[i].Notiz);
          let typ = checkIsOld(recipeList[i].Therapie);
          setCurrTyp(typ);
          setUpload((prevData) => ({
            ...prevData,
            TerminDauer: typ.TypID === "Dummy" ? 0 : parseInt(typ.Dauer, 10),
          }));
          break;
        }
      }
    }
    if (name === "TerminStunde") {
      if (parseInt(terminEnd.Stunde) < parseInt(value)) {
        setTerminEnd((prevData) => ({
          ...prevData,
          Stunde: parseInt(value),
        }));
      }
    }
    if (name === "TerminSlot") {
      if (parseInt(terminEnd.Minute) <= parseInt(value)) {
        setTerminEnd((prevData) => ({
          ...prevData,
          Minute: (parseInt(value) + 15) % 60,
        }));
      }
    }
  }

  function handleTime(event) {
    const { name, value } = event.target;
    setTerminEnd((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  const handleMultiple = (event) => {
    const value = event.target.value;
    setAnzahl(value); // Assuming setTime is the state update function for `anzahl`
  };

  

  const pickColor = (val) => {
    switch (val) {
      case "Verfügbar":
        return "hsl(120, 80%, 50%)";
      case "Krank":
        return "hsl(40, 80%, 70%)";
      case "Urlaub":
        return "hsl(195, 100%, 72%)";
      default:
        return "hsl(0, 0%, 100%)";
    }
  };

  //Searchbar
  const handleSearchInput = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const input = event.target.value;
    setSearchInput(input);

    const filtered = patientenList.filter((patient) =>
      `${patient.Vorname} ${patient.Nachname}`
        .toLowerCase()
        .includes(input.toLowerCase())
    );
    setFilteredOptions(filtered);

    // Check if there's only one patient in the filtered list or if the filtered list contains an already selected patient. In that case, we do not want the patient to be reselected.
    if (
      filtered.length === 1 ||
      (filtered.filter((patient) => patient.PatientID === upload.PatientID)
        .length === 0 &&
        filtered.length !== 0)
    ) {
      const { PatientID } = filtered[0]; // Get the ID of the patient
      setUpload((prevState) => ({ ...prevState, PatientID })); // Update the PatientID in the upload state
    }
  };



  function renderOptionsHour() {
    let options = [];
    let start = parseInt(
      Moment()
        .hour(upload.TerminStunde)
        .minutes(upload.TerminSlot)
        .add(15, "minutes")
        .format("HH")
    );
    for (let i = start; i <= 18; i++) {
      options.push(
        <option key={i} value={i}>
          {i < 10 ? "0" : ""}
          {i}
        </option>
      );
    }
    return options;
  }

  function renderOptionsMinute() {
    let options = [];
    let start =
      parseInt(upload.TerminStunde) !== parseInt(terminEnd.Stunde)
        ? 0
        : parseInt(
          Moment()
            .hour(upload.TerminStunde)
            .minutes(upload.TerminSlot)
            .add(15, "minutes")
            .format("mm")
        );

    for (let i = start; i <= 45; i += 15) {
      options.push(
        <option value={i} key={i}>
          {i === 0 ? "00" : i}
        </option>
      );
    }

    return options;
  }

  const handleKeyPress = (event) => {

    if (event.key === "Enter") {
      handleSubmit();
    } else if (event.key === "Escape") {
      toggleAdd([]);
    }
  };

  function toggleTermineOhnePatient() {
    Axios.get("https://erlomed-kalender.de:3001/api/get/sonderTermine", {})
      .then((response) => {
        console.log(JSON.stringify(response.data, null, 2))
        setSonderTerminListe(response.data[0]);
        setUpload({
          MitarbeiterID: mitarbeiter.MitarbeiterID,
          TerminTag: upload.TerminTag,
          TerminStunde: upload.TerminStunde,
          TerminSlot: upload.TerminSlot,
          TerminDauer: response.data[0].Dauer,
          TypID: response.data[0].TypID,
          Status: upload.Status,
          vorher: "",
          nachher: "",
          Standort: praxis,
        });
        console.log("Toggled:" + mitarbeiter.MitarbeiterID)
      }).catch((error) => {
        console.error(error);
      })

  }

  return (
    <div
      className="promptBox"
      onKeyDown={handleKeyPress}
      tabIndex={0}
      ref={containerRef}
    >
      <div className="promptTop">
        <h1>Termin hinzufügen:</h1>
        <div className="promptButtonWrapper">
          <button
            className="submitPrompt"
            style={{ fontWeight: "570" }}
            onClick={handleSubmit}
          >
            ✓
          </button>
          <button className="closePrompt" onClick={() => toggleAdd([])}>
            X
          </button>
        </div>
      </div>
      <div className="promptContent">
        <div className="promptRow">
          <div className="patientInfoLabel">Status</div>
          <div className={`patientInfoValue`}>
            <select name="Status" onChange={handleChange} value={upload.Status}>
              <option
                value="Abgeschlossen" style={{ color: "hsl(120, 60%, 50%)" }}>
                Abgeschlossen
              </option>
              <option
                value="Unentschuldigt"
                style={{ color: "hsl(351, 100%, 50%)" }}
              >
                Unentschuldigt
              </option>
              <option value="Krank" style={{ color: "hsl(54, 90%, 70%)" }}>
                Krank
              </option>
              <option value="Urlaub" style={{ color: "hsl(309, 100%, 50%)" }}>
                Urlaub
              </option>
              <option value="Eingeplant" style={{ color: "hsl(0, 0%, 50%)" }}>
                Eingeplant
              </option>
            </select>
          </div>
        </div>
        <div className="promptRow">
          <div className="patientInfoLabel">Zeit selbst auswählen:</div>
          <div className="patientInfoValue">
            <input
              type="checkbox"
              onClick={() => setTypIsCostum(!typIsCostum)}
              //checked={typIsCostum}
            ></input>
          </div>
        </div>
        <div className="promptRow">
          <div className="patientInfoLabel">Sondertermine:</div>
          <div className="patientInfoValue">
            <input
              type="checkbox"
              onClick={() => {
                setTypOhnePatient(!typOhnePatient);
                //setTypIsCostum(!typOhnePatient)
                toggleTermineOhnePatient();
              }}
            ></input>
          </div>
        </div>
        <div className="promptRow">
          <div className="patientInfoLabel">Zeit:</div>
          <div className="patientInfoValue promptInfoTime">
            <select
              value={upload.TerminStunde}
              name="TerminStunde"
              onChange={handleChange}
              style={{ marginRight: "-10px" }}
            >
              {Array.from({ length: 12 }, (_, index) => {
                const hour = index + 7;
                return (
                  <option key={hour} value={hour}>
                    {hour < 10 ? "0" : ""}
                    {hour}
                  </option>
                );
              })}
            </select>
            <select
              value={upload.TerminSlot}
              name="TerminSlot"
              onChange={handleChange}
            >
              <option value="0">00</option>
              <option value="15">15</option>
              <option value="30">30</option>
              <option value="45">45</option>
            </select>
            -
            {typIsCostum ? (
              <>
                <select
                  name="Stunde"
                  style={{ marginRight: "-10px" }}
                  onChange={handleTime}
                >
                  {renderOptionsHour()}
                </select>
                <select name="Minute" onChange={handleTime}>
                  {renderOptionsMinute()}
                </select>
              </>
            ) : (
              <>
                <select name="Stunde fixedTerminEndStunde" value={upload.TypID}>
                  <option value={upload.TypID}>
                    {parseInt(
                      Moment()
                        .hour(upload.TerminStunde)
                        .minute(upload.TerminSlot)
                        .add(upload.TerminDauer, "minutes")
                        .format("HH")
                    ) < 10
                      ? "0"
                      : ""}
                    {parseInt(
                      Moment()
                        .hour(upload.TerminStunde)
                        .minute(upload.TerminSlot)
                        .add(upload.TerminDauer, "minutes")
                        .format("HH")
                    )}
                  </option>
                </select>
                <select name="Minute fixedTerminEndMinute" value={upload.TypID}>
                  <option value={upload.TypID}>
                    {parseInt(
                      Moment()
                        .hour(upload.TerminStunde)
                        .minute(upload.TerminSlot)
                        .add(upload.TerminDauer, "minutes")
                        .format("mm")
                    ) === 0
                      ? "0"
                      : ""}
                    {parseInt(
                      Moment()
                        .hour(upload.TerminStunde)
                        .minute(upload.TerminSlot)
                        .add(upload.TerminDauer, "minutes")
                        .format("mm")
                    )}
                  </option>
                </select>
              </>
            )}
            <input
              type="text"
              value={tag}
              onFocus={(e) => (e.target.type = "date")}
              name="TerminTag"
              id="date"
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className="promptRow">
          <div className="patientInfoLabel">Mehrfach vergeben:</div>
          <div className="patientInfoValue">
            <input
              type="number"
              name="Mehrfach"
              onChange={handleMultiple}
              value={anzahl}
              min="0"
            ></input>
          </div>
        </div>
        {!typOhnePatient ? (
          <div className="promptRow">
            <div className="patientInfoLabel">Raum:</div>
            <div className="patientInfoValue">
              <select name="RaumID" onChange={handleChange} value={upload.RaumID}>
                {raumList.map((raum) => (
                  <option key={raum.RaumID} value={raum.RaumID}>
                    {raum.RaumID}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )
          :
          (<></>)
        }

        <div className="patientRowSeperator"></div>
        <div className="promptRow">
          <div className="patientInfoLabel">Mitarbeiter:</div>
          <div className="patientInfoValue">
            <select
              name="MitarbeiterID"
              onChange={handleChange}
              value={upload.MitarbeiterID}
            >
              {mitarbeiterList.map((mitarbeiter) => (
                <option
                  style={{ color: pickColor(mitarbeiter.Verfügbarkeit) }}
                  key={mitarbeiter.MitarbeiterID}
                  value={mitarbeiter.MitarbeiterID}
                >
                  {mitarbeiter.Vorname} {mitarbeiter.Nachname}
                </option>
              ))}
            </select>
          </div>
        </div>
        {!typOhnePatient ? (

          <div className="promptRow">
            <div className="patientInfoLabel">Patient:</div>
            <div className="patientInfoValue">
              <select name="PatientID" onChange={handleChange}>
                {filteredOptions.map((patient) => (
                  <option key={patient.PatientID} value={patient.PatientID}>
                    {patient.Vorname} {patient.Nachname}
                  </option>
                ))}
              </select>
              <input
                className="searchPatient"
                placeholder="Suche"
                type="text"
                value={searchInput}
                onChange={handleSearchInput}
              />
            </div>
          </div>

        )
          :
          (<></>)}


        <div className="patientRowSeperator" />

        {!typOhnePatient ? (
          <div className="promptRow">
            <div className="patientInfoLabel">Rezept:</div>
            <div
              className="patientInfoValue"
              style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
            >
              <select
                style={{ textAlign: "center" }}
                name="RezeptID"
                onChange={handleChange}
                value={upload.RezeptID}
              >
                {recipeList.map((recipe) => (
                  <option key={recipe.RezeptID} value={recipe.RezeptID}>
                    {recipe.Eintragung},{" "}
                    {typList.filter(
                      (typ) => parseInt(recipe.Therapie) === parseInt(typ.TypID)
                    ).length > 0
                      ? `${typList.filter(
                        (typ) =>
                          parseInt(recipe.Therapie) === parseInt(typ.TypID)
                      )[0].Beschreibung
                      },`
                      : ""}{" "}
                    {typList.filter(
                      (typ) => parseInt(recipe.Therapie) === parseInt(typ.TypID)
                    ).length > 0
                      ? typList.filter(
                        (typ) =>
                          parseInt(recipe.Therapie) === parseInt(typ.TypID)
                      )[0].Typ
                      : recipe.Therapie}
                  </option>
                ))}
              </select>
              {IsOld && (
                <span style={{ color: "red" }}>
                  Rezept ist veraltet und sollte nicht genutzt werden. Bearbeiten
                  Sie das Rezept und wählen Sie eine Therapie aus der Liste aus
                  oder wählen Sie die Zeit selber aus!
                </span>
              )}
            </div>
          </div>
        )
          :
          // Für Termine, die nicht an Patienten gebunden sind, wird getTermineOhnePatient() aufgerufen für aktuelle Liste, die hier gerendert werden soll
          (
            <div className="promptRow">
              <div className="patientInfoLabel">Sondertermin:</div>
              <div
                className="patientInfoValue"
                style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
              >
                <select
                  style={{ textAlign: "center" }}
                  name="TypID"
                  onChange={handleChange}
                  value={upload.TypID}
                >
                  {typList
                    .filter(typ => parseInt(typ.isSonderTermin) === 1)
                    .map(termin => (
                      <option key={termin.TerminID} value={termin.TypID}>
                        {termin.Typ} {termin.Dauer}
                      </option>
                    ))}

                </select>
              </div>
            </div>
          )}

        {!typOhnePatient ? (
          <div className="promptRow">
          <div className="promptRow" style={{ justifyContent: "left" }}>
            <div className="patientInfoLabel">Notiz:</div>
            <div className="patientInfoValue">{currNote ? currNote : []}</div>
          </div>
        </div>
        ) : 
        (<></>)}
        
        <div className="patientRowSeperator" />
        <div className="promptRow" style={{ justifyContent: "left" }}>
          <div className="patientInfoLabel" style={{ width: "20%" }}>
            Vorher:
          </div>
          <div className="promptInfoText">
            <textarea name="vorher" onChange={handleChange}></textarea>
          </div>
        </div>
        <div className="promptRow" style={{ justifyContent: "left" }}>
          <div className="patientInfoLabel" style={{ width: "20%" }}>
            Nachher:
          </div>
          <div className="promptInfoText">
            <textarea name="nachher" onChange={handleChange}></textarea>
          </div>
        </div>
      </div>
    </div>
  );
}

import {useRef} from "react"

export default function PrintButton ({contentRef}){
    const handlePrint = () => {
        window.print();
      };
    
      return (
        <div>
          <button onClick={handlePrint}>Print</button>
          <div ref={contentRef}>
            {/* Content to be printed */}
            {/* The contentRef will reference the parent component */}
          </div>
        </div>
      );
  };
  
 
  
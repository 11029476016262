import "./patientStyle.css"
import {useState} from "react"
import Axios from "axios"

export default function PatientEdit(props) {
    const [closing, setClosing] = useState(false);

    ////console.log("Das ist die PatientID " + props.patient.PatientID);

    const [infos, setInfos] = useState(props.patient);
    //console.log("Das ist der Patient "+ JSON.stringify(infos, null, 2));
    function handleChange(event) {
        const { name, value } = event.target;
        setInfos(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const handleSubmit = (event) => {
        //console.log("Neue Daten werden submitted");
        event.preventDefault();
        Axios.post('https://erlomed-kalender.de:3001/api/post/patientEdit', infos)
            .then(response => {
                //console.log(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        ////console.log("Diese infos werden an den useState übergeben " + JSON.stringify(infos, null, 2))
        props.closeEdit();
        
        props.updatePatient(infos);
        //console.log("infos passed from edit to info: " + JSON.stringify(infos, null, 2))
    }

    const deletePatient = () => {
        Axios.delete('https://erlomed-kalender.de:3001/api/delete/deletePatient', {
            params: {
                ID: props.patient.PatientID
            }
        })
            .then(response => {
                //console.log('Spalte erfolgreich gelöscht');

            })
            .catch(error => {
                console.error('Fehler beim Löschen der Spalte:', error);

            });
        props.handleDelPatient();
        props.updatePatient({
            PatientID: "",
        })
    }

    return (
        <>
            <div className={`blurrB ${closing ? "closing" : ""}`}></div>
            <div className={`patientEdit ${closing ? "closing" : ""}`} >
                <div className="patientInfoHeader">
                    <h2>{infos.Vorname} {infos.Nachname}</h2>
                    <div className="patientInfoHeaderButtonWrapper">
                        <button className="deleteButton" onClick={() => deletePatient()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                        </button>
                        <button className="closeButton" onClick={()=>props.closeEdit()}>X</button>
                    </div>
                </div>
                {/*<div className="patientRowSeperator" style={{borderColor: "black", border: "2px solid"}}/>*/}
                <div className="patientInfoContent">
                    <div className="patientInfoKrankenkasse">
                        <h4>Allgemeine Informationen</h4>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">
                                Vorname:
                            </div>
                            <input type="text" name="Vorname" value={infos.Vorname} onChange={handleChange}></input>
                        </div>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">Nachname:</div>
                            <input type="text" name="Nachname" value={infos.Nachname} onChange={handleChange}></input>
                        </div>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">Namenszusatz:</div>
                            <input type="text" name="Namenszusatz" value={infos.Namenszusatz} onChange={handleChange}></input>
                        </div>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">Geburtstag:</div>
                            <input value={infos.Geburtstag} type="text" onFocus={(e) => (e.target.type = "date")} onBlur={(e) => (e.target.type = "text")} name="Geburtstag" id="date" onChange={handleChange}></input>
                        </div>

                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">Adresse:</div>
                            <div className="patientInfoValue">
                                <input type="text" name="Str" value={infos.Str} onChange={handleChange}></input> <input type="text" name="Hnr" style={{ width: "2rem" }} value={infos.Hnr} onChange={handleChange}></input>,
                                <input type="text" name="Ort" value={infos.Ort} onChange={handleChange}></input> <input type="text" name="Plz" value={infos.Plz} onChange={handleChange}></input></div>
                        </div>
                    </div>
                    <div className="patientRowSeperator" />
                    <div className="patientInfoKrankenkasse">
                        <h4>Krankenkasse</h4>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">Krankenkassensitz:</div>
                            <input type="text" name="Krankenkassensitz" value={infos.Krankenkassensitz} onChange={handleChange}></input>
                        </div>
                    </div>
                    <div className="patientInfoRow">
                        <div className="patientInfoLabel">Versicherungsnummer:</div>
                        <input type="text" name="VersNr" value={infos.VersNr} onChange={handleChange} ></input>
                    </div>
                    <div className="patientRowSeperator" />
                    <div className="patientInfoKontaktdaten">
                        <h4>Kontaktdaten</h4>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel" >E-mail:</div>
                            <input type="Email" size="50" maxlength="50" required name="Email" value={infos.Email} onChange={handleChange}></input>
                        </div>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">Telefonnummer:</div>
                            <input type="Tel" name="Tel" value={infos.Tel} onChange={handleChange}></input>
                        </div>
                    </div>
                </div>
                <div className="patientRowSeperator" />
                {//<div className="patientInfoTextArea">

                    //<textarea placeholder="Notizen zum Patienten:"></textarea>
                    // </div>
                }
                <div className="patientInfoFooter">
                    <button className="patientEditSubmit" onClick={handleSubmit}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                        </svg>
                        <span>Übernehmen</span>
                    </button>

                </div>
            </div>
        </>
    )
}
import moment from "moment"
import {useState, useEffect } from "react"
import axios from "axios";

export default function IssueAdd({toggle}){
    const [currUser, setCurrUser] = useState([]);
    const [uploadData, setUploadData] = useState(
        {
            IssueText : "",
            IssueDate : moment(),
            IssueUser : currUser,
        }
    )
    useEffect(()=> {
        const fetchData = async () => {
            try {
              const response = await axios.get('https://erlomed-kalender.de:3001/api/auth/getCurrUser');
              const varProfil = response.data.MitarbeiterID;
              ////console.log(JSON.stringify(varProfil, null, 2))
              setCurrUser(varProfil);
              setUploadData(prevData => ({
                ...prevData,
                IssueUser: varProfil
              }))
            } catch (error) {
              console.error(error);
            }
          };
          fetchData();
    }, [])

    

  
        ////console.log(JSON.stringify(uploadData, null, 2))
    function handleChange(event) {
        const { name, value } = event.target;
        setUploadData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    function submit(){
        axios.post("https://erlomed-kalender.de:3001/api/post/issue", uploadData)
        .then(response => {
            //console.log("Nice cock")
        }).catch(error => {
            //console.log(error)
        })
        toggle()
    }

    return(
        <div className="issueAddWrapper">
            <div className="issueAddHeader">
            <div>Vorschläge</div>
            <div style={{display: "flex", gap: ".5rem"}}>
            <button className="issueAddButton issueAddSubmit" onClick={()=>submit()}>
                ✓
                </button>
                <button className="issueAddButton issueAddClose" onClick={()=>toggle()}>
                X
                </button>
            </div>
               
            </div>
            <div className="issueAddText">
            <textarea name="IssueText" onChange={handleChange} placeholder="Verbesserungen und Vorschläge hier eingeben: "></textarea>
             </div>
        </div>
    )
}
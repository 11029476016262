import "./calendarNav.css"
import moment from "moment"
import ReactToPrint from 'react-to-print';
import PrintButton from './PrintButton';
import { useEffect, useRef, useState } from "react";
import axios from "axios";

export default function CalendarNav(props) {
    ////console.log(props.now)
    
    const nextWeek = () => {
        props.setCurrentWeek(props.now.clone().add(1, "w")); // Zustand auf die nächste Woche aktualisieren
    };

    const previousWeek = () => {
        props.setCurrentWeek(props.now.clone().subtract(7, "days")); // Zustand auf die letzte Woche aktualisieren
    };
    return (
        <div className="calendarNav">
            <div className="navButton" onClick={() => previousWeek()}>←</div>
            <div className="calendarNavOptions">
                <CalendarDropdownLocation praxis={props.praxis} changeLocation={props.changeLocation} />
            </div>
            <div className="calendarNavDateWrappper">
                <CalendarDropdownDate setCurrentWeek={props.setCurrentWeek} />
            </div>
            <div className="navButton" style={{ marginRight: "1.5rem" }} onClick={() => nextWeek()}>→</div>
        </div>

    )
}



function CalendarDropdownUser() {
    let userCount = ['User1', 'User2', 'User3', 'User4']
    return (
        <div className="calendarDropdownUser">
            <button className="dropButton" style={{ width: "8rem" }}>Mitarbeiter</button>
            <CalendarDropdownContent counter={userCount} />
        </div>
    )
}

function CalendarDropdownMode(props) {
    let userCount = ['Allgemeine Ansicht', 'Einzelansicht']
    return (
        <div className="calendarDropdownUser" >
            <button className="dropButton" style={{ width: "8rem" }}>Ansicht</button>
            <CalendarDropdownContent counter={userCount} />
        </div>
    )
}



function CalendarDropdownContent(props) {
    return (
        <div className="calendarDropdownContent">
            {props.counter.map((item, keys) => {
                return (<CalendarDropdownOption key={keys} name={item}></CalendarDropdownOption>)
            })}
        </div>
    )
}

function CalendarDropdownOption(props) {
    return (
        <div className="calendarDropdownOption">{props.name}</div>
    )
}



function CalendarDropdownDate(props) {
    const today = moment();
    const formattedDate = today.format('DD.MM.YYYY');

    const handleChange = (event) => {
        const selectedDate = moment(event.target.value);
        const firstDayOfWeek = selectedDate.clone().startOf('week');

        props.setCurrentWeek(firstDayOfWeek);
    };

    return (
        <div className="calendarDropdownDate">
            Datum:{' '}
            <input
                type="text"
                placeholder={formattedDate}
                onFocus={(e) => (e.target.type = "date")}
                onChange={handleChange}
                style={{
                    border: 'none',
                    alignSelf: 'center',
                    fontSize: '1rem',
                    backgroundColor: '#F2F3F5',
                    color: 'black',
                    fontFamily: 'inherit',
                    fontWeight: 'bold',
                    fontStyle: 'inherit',
                }}
            />
        </div>
    );
}

function CalendarDropdownLocation({ praxis, changeLocation }) {
    const [locationList, setLocationList] = useState([])
    const [currLocation, setCurrLocation] = useState(praxis)    
    useEffect(() => {
        axios.get("https://erlomed-kalender.de:3001/api/get/locations")
            .then(response => {
                setLocationList(response.data)
            })
        axios.get("https://erlomed-kalender.de:3001/api/auth/getCurrUser")
        .then(response => {
            setCurrLocation(response.data.Standort)
            //console.log("TestPraxis: " + JSON.stringify(response.data, null, 2))
            //TODO, seperate backend method for currLocation
        })
    }, [])

    function handleLocationChange(event) {
        const value = event.target.value
        setCurrLocation(value);
        changeLocation(value);
    }
    return (
        <div className="calendarDropdownLocation">
            <select value={currLocation} onChange={handleLocationChange}>
                {locationList.map((location) => (
                    <option key={location.PraxisID} value={location.PraxisID}>{location.Str}, {location.Stadt}</option>
                ))}
            </select>
        </div>
    )
}
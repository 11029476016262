import { useEffect, useState } from "react"
import axios from "axios"

export default function KürzelEditBox({ toggleEditMode, typ, typeListe, setTypeListe }) {
    const [uploadData, setUploadData] = useState(typ);
    //console.log(JSON.stringify(uploadData, null, 2))
    function submit() {
        if(uploadData.Dauer%15 !== 0){
            alert("Dauer muss ein Vielfaches von 15 sein")
            return;
        }
        updateKürzel();
        toggleEditMode();
    }
    function updateData(event) {
        const { name, value } = event.target;
        setUploadData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    function updateKürzel() {
        //console.log("Sending")
        
        axios.post("https://erlomed-kalender.de:3001/api/post/terminTypEdit", uploadData)
            .then(response => {
                //console.log(response)
            }).catch(error => {
                //console.log("Fehler beim Aktualisieren des Typs: " + error)
            })
            for(let i = 0; i<typeListe.length; i++){
                if(typeListe[i].TypID === uploadData.TypID){
                    const newList = typeListe;
                    newList[i] = uploadData;
                    setTypeListe(newList)
                    break;
                }
            }
    }
    return (
        <div className="addKürzelBox">
            <div style={{ display: "flex", textAlign: "center", alignItems: "center", width: "100%", height: "100%", justifyContent: "space-between" }}>
                <input value={uploadData.Typ} placeholder="Kürzel" type="text" name="Typ" onChange={updateData} style={{ width: "20%" }}>
                </input>
                <input value={uploadData.Beschreibung} placeholder="Beschreibung" type="text" name="Beschreibung" onChange={updateData} style={{ width: "60%" }}>
                </input>
                <input value={uploadData.Dauer} placeholder="Dauer" type="number" step={15} min={15} max={660} name="Dauer" onChange={updateData} style={{ width: "10%" }}>
                </input>
            </div>
            <div style={{ display: "flex", justifyContent: "center", gap: "1rem", height: "100%", width: "100%", alignItems: "center" }}>
                <button style={{ border: "1px solid #b1b1b1", backgroundColor: "white", borderRadius: "5px", padding: "4px" }} onClick={() => submit()}>
                    Bestätigen
                </button>
                <button style={{ border: "1px solid #b1b1b1", backgroundColor: "white", borderRadius: "5px", padding: "4px" }} onClick={() => toggleEditMode()}>
                    Schließen
                </button>
            </div>
        </div>
    )
}
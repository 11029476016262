import "./calendarstyle.css";
import "./calendarPrint.css";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import "moment/locale/de";

export default function CalendarDayNote(props) {
  const [hasTermin, setHasTermin] = useState(false);
  const [aktuellerTermin, setAktuellerTermin] = useState(props.termin[0] || {});
  const [patientVorname, setPatientVorname] = useState({});
  const [currTyp, setCurrTyp] = useState({});
  // if(props.termin[0]){
  // console.log("Typ : " + props.termin[0].TypID + " for " + props.stunde + " " + props.slot);
  // console.log("Termine: " + JSON.stringify(props.termin[0], null, 2));
  // console.log("Vorname : " + props.termin[0].Vorname + " for " + props.stunde + " " + props.slot);
  // }
  useEffect(() => {
    if (props.termin.length > 0) {
      setHasTermin(true);
      setAktuellerTermin(props.termin[0]);
      const filteredTyp = props.typListe.find(typ => parseInt(typ.TypID) === parseInt(props.termin[0].TypID));
      setCurrTyp(filteredTyp || {});
      // Debugging output, remove or comment out for production
      //console.log("Filtered Typ:", JSON.stringify(filteredTyp), " with isSonderTermin", filteredTyp?.isSonderTermin + " in note: " + props.stunde + " " + props.slot);
    } else {
      setHasTermin(false);
      setAktuellerTermin({});
      setPatientVorname({});
    }
  }, [props.termin, props.typListe]);


  function handlePrompt() {
    if (Object.keys(aktuellerTermin).length === 0) {
      props.toggleAdd(props.date, props.user, props.stunde, props.slot);
    } else {
      props.setPrompt(aktuellerTermin, props.user, props.stunde, props.slot);
    }
  }

  function darkenColor(hslColor, amount) {
    let components = hslColor.match(/\d+/g).map(Number);
    let [hue, saturation, lightness] = components;
    let darkenedLightness = Math.max(0, lightness - amount);
    return `hsl(${hue}, ${saturation}%, ${darkenedLightness}%)`;
  }

  const getColor = (status) => {
    switch (status) {
      case "Abgeschlossen": return "hsl(120, 80%, 85%)";
      case "Eingeplant": return "hsl(0, 0%, 85%)";
      case "Fahrt": return "hsl(195, 100%, 72%)";
      case "Unentschuldigt": return "hsl(351, 100%, 70%)";
      case "Urlaub": return "hsl(309, 100%, 85%)";
      case "Krank": return "hsl(54, 90%, 70%)";
      case "Pause": return "hsl(272, 100%, 87%)";
      case "Hausbesuch": return "hsl(30, 100%, 85%)";
      default: return "hsl(0, 0%, 100%)";
    }
  };

  let backgroundColor = (props.termin[0] && props.termin[0].TypID && currTyp) ? (getColor(currTyp.Typ) || "") : (getColor(aktuellerTermin.Status || ""));
  if (props.position === "first") {
    backgroundColor = darkenColor(backgroundColor, 11);
  }

  return (
    <div
      style={{ backgroundColor, fontSize: "14px" }}
      className={`calendarDayNote ${hasTermin ? "hasTermin" : ""}`}
      onClick={handlePrompt}
    >
      {props.position === "first" && (
        <>
          {aktuellerTermin.Status === "Hausbesuch" ? (
            <>({aktuellerTermin.RaumID})</>
          ) : (
            <div className="raumDiv">{"R:" + props.termin[0].TypID ? aktuellerTermin.RaumID : ""}</div>
          )}
          {(props.termin[0].Vorname) ? (
            <>
              {props.termin[0].Vorname.charAt(0)}. {props.termin[0].Nachname}
            </>
          ) : (currTyp ? currTyp.Typ : " ")}
        </>
      )}
      {props.position === "last" && <div className="arrowDiv">{"←"}</div>}
    </div>
  );
}

import "./calendarstyle.css";
import CalendarNav from "./CalendarNav";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import Moment from "moment";
import "moment/locale/de";

export default function CalendarPrompt(props) {
  const containerRef = useRef(null);
  const [edit, setEdit] = useState(false);
  const [patientData, setPatientData] = useState([]);
  const [recipe, setRecipe] = useState([]);
  const [recipeTyp, setRecipeTyp] = useState([]);
  const [completedCounter, setCompletedCounter] = useState([]);
  const [special, setSpecial] = useState(false);
  let patient = patientData[0];
  let patientVorname = "";
  let patientNachname = "";
  let zeit = Moment().hour(props.currentStunde).minute(props.currentSlot);
  /*if (props.termin && props.termin !== []) {
        zeit = Moment().hour(props.termin.TerminStunde).minute(props.termin.TerminSlot);
    }*/
  console.log("Prompt: " + JSON.stringify(props.termin, null, 2));
  if (props.termin) {
    zeit = Moment()
      .hour(props.termin.TerminStunde)
      .minute(props.termin.TerminSlot);
  }
  let status = props.termin.Status;
  if (patient) {
    patientVorname = patient.Vorname;
    patientNachname = patient.Nachname;
  }

  useEffect(() => {
    //containerRef.current.focus();
    Axios.get('https://erlomed-kalender.de:3001/api/get/countFinishedSessionsUntilDateTime', {
            params: {
                ID: recipe.RezeptID,
                TerminTag: props.termin.TerminTag,
                TerminStunde: props.termin.TerminStunde,
                TerminSlot: props.termin.TerminSlot,
            }
        }).then((response) => {
            setCompletedCounter(response.data.count)

        })
        if(!props.termin.TypID){
          Axios.get("https://erlomed-kalender.de:3001/api/get/terminPatient", {
            params: {
              ID: props.termin.PatientID,
            },
          })
            .then((response) => {
              setPatientData(response.data);
            })
            .catch((error) => {
              console.error("Fehler beim Aufrufen des Patienten: " + error);
            });
      
          Axios.get("https://erlomed-kalender.de:3001/api/get/rezept", {
            params: {
              ID: props.termin.RezeptID,
            },
          })
            .then((response) => {
              setRecipe(response.data[0]);
              setRecipeTyp(
                props.typListe.filter(
                  (typ) => parseInt(typ.TypID) === parseInt(response.data[0].Therapie)
                )
              );
            })
            .catch((error) => {
              console.error("Fehler beim Aufrufen des Rezepts: " + error);
            });
        }else{
          setSpecial(true);
        }
    
  }, [props.termin.PatientID]);

  const editTermin = () => {
    setEdit(!edit);
    closePrompt();
  };

  const closePrompt = () => {
    setPatientData([]);
    props.setPrompt([]);
  };

  const getColor = (value) => {
    switch (value) {
      case "Abgeschlossen":
        return "hsl(120, 80%, 50%)";
      case "Eingeplant":
        return "hsl(0, 0%, 50%)";
      case "Fahrt":
        return "hsl(195, 100%, 50%)";
      case "Unentschuldigt":
        return "hsl(351, 100%, 50%)";
      case "Urlaub":
        return "hsl(309, 100%, 50%)"; 
      case "Krank":
        return "hsl(54, 90%, 50%)";
      case "Pause":
        return "hsl(272, 100%, 50%)"; 
      case "Hausbesuch":
        return "hsl(30, 100%, 50%";
      default:
        return "hsl(0, 0%, 50%)";
    }
  };

  let color = getColor(status);

  const handleKeyPress = (event) => {
    //console.log("Fire")
    if (event.key === "Enter") {
      props.toggleEdit();
    } else if (event.key === "Escape") {
      closePrompt();
    }
  };

  const handleEdit = () => {
    if(props.termin[0]){
      props.toggleEdit(props.termin[0], props.mitarbeiter, props.currentStunde, props.currentSlot);
    }else{
      props.toggleEdit();
    }
  }

  //console.log("check " + JSON.stringify(recipeTyp, null, 2));
  return (
    <div
      className="promptBox"
      onKeyDown={handleKeyPress}
      tabIndex={0}
      ref={containerRef}
    >
      <div className="promptTop">
        <h1>Termin {props.termin.TerminID}</h1>
        <div className="promptButtonWrapper">
          <button className="changePrompt" onClick={props.toggleEdit}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
          <button className="closePrompt" onClick={() => closePrompt()}>
            X
          </button>
        </div>
      </div>
      <div className="promptContent">
        <div className="promptRow">
          <div className="patientInfoLabel">Status</div>
          <div className={`patientInfoValue`} style={{ color }}>
            {props.termin.Status}
          </div>
        </div>
        <div className="promptRow">
          <div className="patientInfoLabel">Zeit:</div>
          <div className="patientInfoValue promptInfoTime">
            <h4>{zeit.format("HH:mm")}</h4>
            {props.termin.TerminDauer && (
              <>
                -
                <h4>
                  {zeit
                    .add(props.termin.TerminDauer, "minutes")
                    .format("HH:mm")}
                </h4>
              </>
            )}
            {Moment(props.termin.TerminTag).format("dddd, DD.MM.yyyy")}
          </div>
        </div>
        {props.termin.Typ !== "HB" && !special && (
          <div className="promptRow">
            <div className="patientInfoLabel">Raum:</div>
            <div className="patientInfoValue">{props.termin.RaumID}</div>
          </div>
        )}
        <div className="patientRowSeperator"></div>
        <div className="promptRow">
          <div className="patientInfoLabel">Mitarbeiter:</div>
          <div className="patientInfoValue">
            {props.mitarbeiter.name
              ? `${props.mitarbeiter.name}`
              : null}
          </div>
        </div>
        {!special && (
        <>
          <div className="promptRow">
            <div className="patientInfoLabel">Patient:</div>
            <div className="patientInfoValue">
              {patientVorname} {patientNachname}
            </div>
          </div>
          <div className="patientRowSeperator" />
          <div className="promptRow">
            <div className="patientInfoLabel">Rezept:</div>
            <div className="patientInfoValue">
              {recipeTyp.length === 0 ? recipe.Therapie : recipeTyp[0].Typ}
            </div>
          </div>
        </>
      )}
       
        
        <div className="promptRow">
          <div className="patientInfoLabel">Notiz:</div>
          <div
            className="patientInfoValue"
            style={{
              overflow: "scroll",
              width: "100%",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              textAlign: "left",
            }}
          >
            {recipe.Notiz}
          </div>
        </div>
        <div className="promptRow">
          <div className="patientInfoLabel">Durchgeführte Termine:</div>
          <div className="promptInfoValue">
            {completedCounter} von {recipe.Einheiten}
          </div>
        </div>
        <div className="patientRowSeperator" />
        <div className="promptRow" style={{ justifyContent: "left" }}>
          <div className="patientInfoLabel">Vorher:</div>
          <div className="promptInfoText" style={{ justifySelf: "left" }}>
            {props.termin.vorher}
          </div>
        </div>
        <div className="promptRow" style={{ justifyContent: "left" }}>
          <div className="patientInfoLabel">Nachher:</div>
          <div className="promptInfoText">{props.termin.nachher}</div>
        </div>
      </div>
    </div>
  );
}

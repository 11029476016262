import axios from "axios";
import { useState } from "react";

export default function KürzelAddBox({ toggleAddMode, typeListe, setTypeListe }) {
    const [uploadData, setUploadData] = useState({
        Typ: "",
        Beschreibung: "",
        Dauer: 0,
    })

    function addKürzel() {
        axios.post("https://erlomed-kalender.de:3001/api/post/terminTyp", uploadData)
            .then(response => {
                const addedTyp = response.data.addedTyp;
                //console.log("Success");
                setTypeListe(prevList => [...prevList, addedTyp]); // Update typeListe with the newly added object
            }).catch(error => {
                console.error("Fehler beim Hinzufügen des Typs " + error);
            });
    }

    function submit() {
        if(uploadData.Dauer%15 !== 0){
            alert("Dauer muss ein Vielfaches von 15 sein")
            return;
        }
        addKürzel();
        setUploadData({
            Typ: "",
            Beschreibung: "",
            Dauer: 0
        });
    }

    function updateData(event) {
        const { name, value } = event.target;
        setUploadData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }


    function close() {
        toggleAddMode();
    }

    return (
        <div className="addKürzelBox">
            <div style={{ display: "flex", textAlign: "center", alignItems: "center", width: "100%", height: "100%", justifyContent: "space-between" }}>
                <input value={uploadData.Typ} placeholder="Kürzel" type="text" name="Typ" onChange={updateData} style={{ width: "20%" }}>
                </input>
                <input value={uploadData.Beschreibung} placeholder="Beschreibung" type="text" name="Beschreibung" onChange={updateData} style={{ width: "60%" }}>
                </input>
                <input value={uploadData.Dauer} placeholder="Dauer" step={15} min={15} max={660} type="number" name="Dauer" onChange={updateData} style={{ width: "10%" }}>
                </input>
            </div>
            <div style={{ display: "flex", justifyContent: "center", gap: "1rem", height: "100%", width: "100%", alignItems: "center" }}>
                <button style={{ border: "1px solid #b1b1b1", backgroundColor: "white", borderRadius: "5px", padding: "4px" }} onClick={() => submit()}>
                    Bestätigen
                </button>
                <button style={{ border: "1px solid #b1b1b1", backgroundColor: "white", borderRadius: "5px", padding: "4px" }} onClick={() => close()}>
                    Abbrechen
                </button>
            </div>
        </div>
    )
}
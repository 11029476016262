import "./rezeptStyle.css";
import "./rezeptAddStyle.css";
import Axios from 'axios';
import React, { useState, useEffect, createContext, useContext } from "react";
import RezeptAdd from './RezeptAdd';
import RezeptEdit from './RezeptEdit';

//Kontext für 


export default function Rezept({ handleClose, patient }) {

    const [recipeList, setRecipeList] = useState([]);
    const [typList, setTypList] = useState([])
    const [typ, setTyp] = useState([])
    const [showComponent, setShowComponent] = useState(false);
    const [showRezeptAdd, setShowRezeptAdd] = useState(false);
    const [showRezeptEdit, setShowRezeptEdit] = useState(false);
    const [currRezept, setCurrRezept] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const handleHide = () => {
        setShowComponent(!showComponent)
        setTimeout(() => {
            handleClose();
        }, 650);
    }

    const handleEdit = (rezept)=> {
        //console.log("Fire")
        setCurrRezept(rezept);
        setShowRezeptEdit(!showRezeptEdit);
        
    }

    const handleAdd = () => {
        setShowRezeptAdd(!showRezeptAdd);
    }

    useEffect(() => {
        Axios.get('https://erlomed-kalender.de:3001/api/get/getRecipe', {
            params: {
                ID: patient.PatientID
            }
        }).then((response) => {
            if(response.data.length === 0){
                
            }else{
                setRecipeList(response.data)
                
            }
            console.log(JSON.stringify(response.data, null, 2) + response.data.length)
        })

        Axios.get("https://erlomed-kalender.de:3001/api/auth/getCurrUser")
        .then(response => {
            if(response.data.isAdmin === 1){
                setIsAdmin(true);
            }else{
                setIsAdmin(false);
            }
        }).catch(error => {
            console.error("Fehler bei Verifizierung: " + error)
        })
       
        Axios.get("https://erlomed-kalender.de:3001/api/get/terminTypenNonSonder")
        .then(response=>{
            setTypList(response.data);
        }).catch(error=>{
            console.error("Fehler beim aufrufen des Typs: " + error)
        })
        console.log("Test");
    }, []);

    if (recipeList.length !== 0) {
        return (
            
            <div className={`rezeptWrapper ${showComponent ? "closing" : ""}`} >
             {(showRezeptAdd) && <RezeptAdd updateRecipeList={setRecipeList} recipeList={recipeList} onClose={() => setShowRezeptAdd(false)} patient={patient}/>}
            
             {(showRezeptEdit) && <RezeptEdit typList={typList} setTyp={setTyp} onClose={() => setShowRezeptEdit(false)} updateRecipeList={setRecipeList} recipeList={recipeList} rezept={currRezept}/>}
           
                <div className="rezeptHeader">
                    <div className="rezeptHeaderName">
                        <h2>{patient.Vorname} {patient.Nachname}</h2>
                    </div>
                    <div className="rezeptButtonClose">
                        {isAdmin && <button className="rezeptEditButton" onClick={() => handleAdd()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                        </button>}
                        <button className="closeButton" onClick={() => handleHide()}>X</button>
                    </div>
                </div>
                {recipeList.map((val, keys) => {
                    return (
                        
                        <RezepteBox typList={typList} typ={typ} setTyp={setTyp} handleEdit={handleEdit} key={keys} patientT={patient} rezept={val} show={() => handleClose()} />
                        
                    )
                })}
            </div>
        )
    } else {
        return (
            <div className={`rezeptWrapper ${showComponent ? "closing" : ""}`} >
            {(showRezeptAdd) && <RezeptAdd updateRecipeList={setRecipeList} recipeList={recipeList} onClose={() => setShowRezeptAdd(false)} patient={patient} />}
                <div className="rezeptHeader">
                    <div className="rezeptHeaderName">
                        <h2>{patient.Vorname} {patient.Nachname}</h2>
                    </div>
                    <div className="rezeptButtonClose">
                        {isAdmin && <button className="rezeptEditButton" onClick={() => handleAdd()}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                            </svg>
                        </button>}
                        <button className="closeButton" onClick={() => handleHide()}>X</button>
                    </div>
                </div>
                <div className="rezeptError">Es sind noch keine Rezepte für {patient.Vorname} {patient.Nachname} vermerkt.</div>
            </div>
        )
    }

}


function RezepteBox(props) {
    
    const [showRecipeInfo, setShowInfo] = useState(false);
    const [close, setClose] = useState(false);
    const [fach, setFach] = useState([])
    useEffect(()=> {
        for(let i = 0; i<props.typList.length; i++){
            if(parseInt(props.typList[i].TypID) === parseInt(props.rezept.Therapie)){
                setFach(props.typList[i].Beschreibung)
            }
        }
    }, [])

    const handleClick = () => {
        if (!close) {
            setShowInfo(!showRecipeInfo);
            setClose(true);
        } else {
            setClose(false);
            setTimeout(() => {
                setShowInfo(!showRecipeInfo);
            }, 200);
        }

    }
    const borderRadiusOff = {
        borderRadius: "10px 10px 0px 0px"
    }
    const borderRadiusOn = {
        borderRadius: "10px 10px 10px 10px"
    }
    return (
        <div className="rezepteBoxWrapper">
            <div className="rezepteBox" style={showRecipeInfo ? borderRadiusOff : borderRadiusOn}>

                <div>
                    Ausstellung: {props.rezept.Ausstellung}
                </div>
                <div>
                    Eintragung: {props.rezept.Eintragung}
                </div>
                <div >
                    Nummer: {props.rezept.RezeptID}
                </div>
                <div >
                    {fach}
                </div>
                <div>
                    <button className="rezeptBoxExpand" onClick={() => handleClick()}>
                        {!showRecipeInfo && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>}
                        {showRecipeInfo &&
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                            </svg>
                        }
                    </button>
                </div>

            </div>
            {showRecipeInfo &&
                <RezeptInfo typ={props.typ} typList={props.typList} setTyp={props.setTyp} handleEdit={props.handleEdit} isClose={close} rezept={props.rezept} />
            }
        </div>
    )



}

function RezeptInfo({ rezept, isClose, handleEdit, typ, typList, setTyp}) {
    const [isAdmin, setIsAdmin] = useState(false);
    const [done, setDone] = useState(0);
    useEffect(() => {
        Axios.get('https://erlomed-kalender.de:3001/api/get/recipeFinishedSessions', {
            params: {
                ID: rezept.RezeptID
            }
        }).then((response) => {
            setDone(response.data.count)

        })
        for(let i = 0; i<typList.length; i++){
            if(parseInt(typList[i].TypID) === parseInt(rezept.Therapie)){
                setTyp(typList[i]);
            }
        }

        Axios.get("https://erlomed-kalender.de:3001/api/auth/getCurrUser")
        .then(response => {
            if(response.data.isAdmin === 1){
                setIsAdmin(true);
            }else{
                setIsAdmin(false);
            }
        }).catch(error => {
            console.error("Fehler bei Verifizierung: " + error)
        })
    }, [rezept]);

    return (
        <div className={`rezeptInfo ${!isClose ? "close" : ""}`}>
            <div className="patientInfoKrankenkasse">
                <div className="patientInfoRow">
                    <div className="patientInfoLabel">Verschriebene Einheiten:</div>
                    <div className="patientInfoValue">{rezept.Einheiten}</div>
                </div>
                <div className="patientInfoRow">
                    <div className="patientInfoLabel">Durchgeführte Termine:</div>
                    <div className="patientInfoValue">{done}</div>
                </div>
                <div className="patientInfoRow">
                    <div className="patientInfoLabel">Therapie Typ:</div>
                    <div className="patientInfoValue">{typ.Typ} {typ.Dauer}</div>
                </div>
                <div className="rezeptInfoNote">
                    <div className="patientInfoLabel"><h4>Notizen:</h4></div>
                    <div className="patientInfoValue">{rezept.Notiz}</div>
                </div>
            </div>
            <div className="rezeptInfoFooter">
               {isAdmin && <button className="rezeptInfoButton" onClick={() => handleEdit(rezept)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>
                    <span>Bearbeiten</span>
                </button>}
            </div>
        </div>
    )
}
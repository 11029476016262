export default function CalendarConfirm(props) {

    const handleConfirm = () => {
        props.delete();
        props.close();
    }

    return (
        <div className='promptBox' style={{ zIndex: "4", top: "50%" }}>
            Sind Sie sicher, dass Sie den Termin löschen möchten?
            <div style={{display:"flex", justifyContent: "center"}}>
                <div className="promptButtonWrapper">
                    <button className="submitPrompt" style={{ fontWeight: "570" }} onClick={handleConfirm}>✓</button>
                    <button className="closePrompt" onClick={props.close}>X</button>
                </div>
            </div>
        </div>
    )
}
import Axios from "axios"
import { useEffect, useState } from "react"

export default function IssueAddButton({ toggle }) {

   
    return (
        <div className="issueButtonAddWrapper">
            <div className="issueButtonAddBox">
            <span>Hinzufügen</span>
                <button className="issueAddButton" onClick={()=> toggle()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </button>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react"
import Axios from 'axios'
import "./calendarstyle.css"
import "./calendarPrint.css"
import CalendarDayNote from './CalendarDayNote'
import moment from "moment"

export default function CalendarDay(props) {
    const hours = [];
   
    for (let i = 7; i <= 18; i++) {
        const terminStunde = props.termine ? props.termine.filter(termin => termin.slots.some(slot => moment(slot, "H:mm").hour() === i)) : [];
        //console.log("CalendarDay: " + JSON.stringify(terminStunde, null, 2));
        hours.push(
            <React.Fragment key={i}>
                <CalendarDayHour typListe={props.typListe} date={props.date} name={props.name} user={props.user} time={i} key={i} termin={terminStunde} toggleAdd={props.toggleAdd} setPrompt={props.setPrompt} />
                <div className="calendarDayHourSeperator" />
            </React.Fragment>
        )
    }
    return (
        <div className="calendarDay" name={props.name} >
            <div className="userTitle">
                {props.user.name ? `${props.user.name.charAt(0)}. ` : ''}{props.user.name ? props.user.name.split(' ')[1] : ''}
            </div>
            {hours}
        </div>
    )
}


function CalendarDayHour(props) {
    const hours = [];
    for (let i = 0; i <= 45; i += 15) {
        const terminMinute = props.termin ? props.termin.filter(termin => termin.slots.some(slot => {
            const slotTime = moment(slot, "H:mm");
            return slotTime.hour() === props.time && slotTime.minute() === i;
        })) : [];

        let position = "mid"; // Default to middle
        if (terminMinute.length > 0) {
            // Check if this is the first or last slot
            terminMinute.forEach(termin => {
                const firstSlot = moment(termin.slots[0], "H:mm");
                const lastSlot = moment(termin.slots[termin.slots.length - 1], "H:mm");
                if (firstSlot.hour() === props.time && firstSlot.minute() === i) {
                    position = "first";
                } else if (lastSlot.hour() === props.time && lastSlot.minute() === i) {
                    position = "last";
                }
            });
        }

        hours.push(
            <React.Fragment key={i}>
                {i !== 0 ? <div className="calendarTimeQuarterSeperator" /> : ""}
                <div className="calendarDayQuarter">
                    <CalendarDayNote
                        typListe={props.typListe}
                        user={props.user}
                        key={i}
                        toggleAdd={props.toggleAdd}
                        termin={terminMinute}
                        stunde={props.time}
                        date={props.date}
                        slot={i}
                        setPrompt={props.setPrompt}
                        position={position}  // Pass the position
                    />
                    <div className="textQuarter">{i === 0 ? "00" : i}</div>
                </div>
            </React.Fragment>
        )
    }
    return (
        <div className="calendarDayHour">
            {hours}
        </div>
    );
}



import React, { useState, useEffect } from "react"
import "./calendarstyle.css"
import "./calendarPrint.css"

export default function CalendarSidebar(props) {
    const hours = [];
    for (let i = 7; i <= 18; i++) {
        hours.push(
            <React.Fragment key={i}>
                <CalendarTime time={i} />
                <div className="calendarTimeQuarterSeperator" />
            </React.Fragment>
        )
    }

    const [isFixed, setIsFixed] = useState(false);
    const [scrollLeft, setScrollLeft] = useState(0);
    // Function to handle scroll event
    const handleScroll = () => {
        if (window.scrollX > 0 && !isFixed) {
            setIsFixed(true);
        } else if (window.scrollX === 0 && isFixed) {
            setIsFixed(false);
        }
        if (isFixed) {
            setScrollLeft(window.scrollX + 20)
        }

    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isFixed]);

    const sidebarStyle = {
        left: `${scrollLeft - 15}px`,
        position: "absolute",
        zIndex: "1",
        marginLeft: "0"
    }

    const defaultStyle = {
        marginLeft: "1rem",
        marginTop: "9.5rem",
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        backgroundColor: "#F2F3F5",
        minHeight: "100vh"
    }


    return (
        <div
            className="calendarSidebar"
            style={{
                ...(isFixed ? sidebarStyle : defaultStyle),
                '@media print': {
                    position: 'fixed',
                    // Add any other styles you want for printing mode
                }
            }}
        >
            {hours}
        </div>
    )
}

function CalendarTime(props) {
    return (
        <div className="calendarTime">
            <div className="time">
                {props.time}
            </div>
            <CalendarTimeQuarter />
        </div>
    )
}

function CalendarTimeQuarter() {
    return (
        <div className="calendarTimeQuarterBox">
            <div className="calendarTimeQuarter">
                00
            </div>
            <div className="calendarTimeQuarterSeperator">
            </div>
            <div className="calendarTimeQuarter">
                15
            </div>
            <div className="calendarTimeQuarterSeperator">
            </div>
            <div className="calendarTimeQuarter">
                30
            </div>
            <div className="calendarTimeQuarterSeperator">
            </div>
            <div className="calendarTimeQuarter">
                45
            </div>
        </div>
    )
}

import {useState, useEffect} from 'react'
import Axios from 'axios';
import Rezept from '../rezepte/Rezepte';

export default function PatientInfo(props) {
    const [closing, setClosing] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [newPatient, setNewPatient] = useState(props.patient);
    ////console.log("info mounted with: " + JSON.stringify(newPatient, null, 2));
    const [showRecipe, setShowRecipe] = useState(false);
    //console.log("NewPatient" + JSON.stringify(newPatient, null, 2))
    useEffect(()=>{
        Axios.get("https://erlomed-kalender.de:3001/api/auth/getCurrUser")
        .then(response => {
            if(response.data.isAdmin === 1){
                setIsAdmin(true);
            }else{
                setIsAdmin(false);
            }
        }).catch(error => {
            console.error("Fehler bei Verifizierung: " + error)
        })
    }, [])

    const onClose = () => {
        setClosing(true);
        setTimeout(() => {
            props.show(props.patient);
        }, 650);
    }

    const onEdit = () => {
        setClosing(true);
        setTimeout(() => {
            //console.log("onEdit " + newPatient);
            props.showEdit(newPatient);
        }, 650);
    }

    const onShowRecipe = () => {
        setClosing(!closing);
        setTimeout(() => {
            setShowRecipe(!showRecipe);
        }, 650);
    }

    const onAddRecipe = () => {
        setClosing(true);
        setTimeout(() => {
            props.showEdit();
        }, 650);
    }



    return (
        <>
            {showRecipe && <Rezept handleClose={() => onShowRecipe()} patient={props.patient} />}
            <div className={`blurrB ${closing ? "closing" : ""}`}></div>
            <div className={`patientInfo ${closing ? "closing" : ""}`} >
                <div className="patientInfoHeader">
                    <h2>{props.patient.Vorname} {props.patient.Nachname}</h2>
                    <button className="closeButton" onClick={onClose}>X</button>
                </div>
                {/*<div className="patientRowSeperator" style={{borderColor: "black", border: "2px solid"}}/>*/}
                <div className="patientInfoContent">
                    <div className="patientInfoRow">
                        <div className="patientInfoLabel">Vorname:</div>
                        <div className="patientInfoValue">{props.patient.Vorname}</div>
                    </div>
                    <div className="patientInfoRow">
                        <div className="patientInfoLabel">Nachname:</div>
                        <div className="patientInfoValue">{props.patient.Nachname}</div>
                    </div>
                    <div className="patientInfoRow">
                        <div className="patientInfoLabel">Namenszusatz:</div>
                        <div className="patientInfoValue">{props.patient.Namenszusatz}</div>
                    </div>
                    <div className="patientInfoRow">
                        <div className="patientInfoLabel">Geburtstag:</div>
                        <div className="patientInfoValue">{props.patient.Geburtstag}</div>
                    </div>

                    <div className="patientInfoRow">
                        <div className="patientInfoLabel">Adresse:</div>
                        <div className="patientInfoValue">{props.patient.Str} {props.patient.Hnr}, {props.patient.Ort}</div>
                    </div>
                    <div className="patientRowSeperator" />
                    <div className="patientInfoKrankenkasse">
                        <h4>Krankenkasse</h4>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">Krankenkassensitz:</div>
                            <div className="patientInfoValue">{props.patient.Krankenkassensitz}</div>
                        </div>
                    </div>
                    <div className="patientInfoRow">
                        <div className="patientInfoLabel">Versicherungsnummer:</div>
                        <div className="patientInfoValue">{props.patient.VersNr}</div>
                    </div>
                    <div className="patientRowSeperator" />
                    <div className="patientInfoKontaktdaten">
                        <h4>Kontaktdaten</h4>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">E-mail:</div>
                            <div className="patientInfoValue">{props.patient.Email}</div>
                        </div>
                        <div className="patientInfoRow">
                            <div className="patientInfoLabel">Telefonnummer:</div>
                            <div className="patientInfoValue">{props.patient.Tel}</div>
                        </div>
                    </div>
                </div>
                <div className="patientRowSeperator" />
                {//<div className="patientInfoTextArea">

                    //<textarea placeholder="Notizen zum Patienten:"></textarea>
                    // </div>
                }
                <div className="patientInfoFooter">
                    {isAdmin && <button className="patientInfoButton" onClick={() => onEdit()}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                        </svg>
                        <span>Bearbeiten</span>
                    </button>}
                    <button className="patientInfoButton" onClick={() => onShowRecipe()}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M6 4.75A.75.75 0 016.75 4h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 4.75zM6 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75A.75.75 0 016 10zm0 5.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM1.99 4.75a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 15.25a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1v-.01zM1.99 10a1 1 0 011-1H3a1 1 0 011 1v.01a1 1 0 01-1 1h-.01a1 1 0 01-1-1V10z" clipRule="evenodd" />
                        </svg>
                        Rezepte anzeigen
                    </button>

                </div>
            </div>
        </>
    )

}
import "./homestyle.css"
import { useEffect, useState } from "react";
import Axios from "axios";
import Issue from "./Issue";
import IssueAddButton from "./IssueAddButton";
export default function IssueBox({toggle}){
    const [issueList, setIssueList] = useState([]);
    
    useEffect(() => {
        Axios.get('https://erlomed-kalender.de:3001/api/get/issues')
            .then(response => {
                setIssueList(response.data);
            })
            .catch(error => {
                //console.log(error);
            });
    }, []);
    
    
    return (
        <div className="issueBox">
        <IssueAddButton toggle={toggle}/>
            {issueList.map((item, keys) => {
                return (<Issue issueObj={item} setIssueList={setIssueList} issueList={issueList} key={keys}></Issue>)
            })}
        </div>
    )
}
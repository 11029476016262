import Moment from 'moment';
import React, { useState, useEffect } from "react";
import "./calendarstyle.css";
import "./calendarPrint.css";
import CalendarDay from './CalendarDay';
import CalendarDayAddUser from './CalendarDayAddUser';

export default function CalendarDayWrapper(props) {
  const [isCurr, setIsCurr] = useState(false);
  const [users, setUsers] = useState([]);  // State to hold the list of users

  useEffect(() => {
      const heute = Moment();
      setIsCurr(props.date === heute.format('DD.MM.YYYY'));

      // Initialize the users state from props
      const initialUsers = Object.keys(props.termineTag).map(mitarbeiterID => {
          const userKey = Object.keys(props.mitarbeiterNameList).find(key => key.startsWith(`${mitarbeiterID} `));
          const user = props.mitarbeiterNameList[userKey];
          return {
              mitarbeiterID,
              name: user ? `${user.Vorname} ${user.Nachname}` : undefined
          };
      }).filter(user => user.name !== undefined);

      setUsers(initialUsers);  // Set the initial list of users
  }, [props.date, props.termineTag, props.mitarbeiterNameList]);

  const onAddUser = () => {
      // Add a new, empty CalendarDay entry
      setUsers(prevUsers => [...prevUsers, { mitarbeiterID: 'new', name: 'Neu', termine: [] }]);
  };

  return (
      <div className="calendarDayWrapper" day={props.day} style={{
        '@media print': {
          pageBreakAfter: 'always !important',
        }
      }}>
        <div className={isCurr ? "dayTitleCurr" : "dayTitle"}>
          {props.dayName} {props.date}
        </div>
        <div className="calendarHourWrapper">
          {users.map((item, index) => {
            const mitarbeiterTermine = props.termineTag[item.mitarbeiterID] || [];
            return (
              <CalendarDay
                typListe={props.typListe}
                praxis={props.praxis}
                toggleAdd={props.toggleAdd}
                setPrompt={props.setPrompt}
                key={item.mitarbeiterID + index}  // ensure unique key
                user={item}
                name={item.name}
                termine={mitarbeiterTermine}
                date={props.date}
              />
            );
          })}
          <button className="calendarDayAddUser" onClick={onAddUser}>+</button>  {/* Add User Button */}
        </div>
        <div className="print-page-break"></div>
      </div>
  );
}

import { useEffect, useState } from "react"
import Axios from "axios";
import "./profilStyle.css"
import ProfilEdit from "./ProfilEdit";
export default function ProfilInfo({ profil, widget, standort, toggle }) { 
    const closeWidget = () => { 

    }

    function toggleEdit(){
        toggle(true);
    }

    const pickColor = (val)=>{
        switch (val) {
            case 'Verfügbar':
                return 'hsl(120, 80%, 50%)';
            case 'Krank':
                return 'hsl(40, 80%, 70%)';
            case 'Urlaub':
                return 'hsl(195, 100%, 72%)';
            default:
                return 'hsl(0, 0%, 100%)';
        }
    }
    const colorVar = pickColor(profil.Verfügbarkeit);
    
    return (
        <div className="profilContainer">
            <div className="profilContainerHeader">
                <h1 style={{ width: "100%" }}>{profil.Vorname} {profil.Nachname}</h1>
                <div className="profilContainerButtons">
                    <button className="profilEditButton" onClick={toggleEdit} >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>
                    </button>
                    {widget && <button className="profilCloseButton" onClick={closeWidget}>
                    </button>}
                </div>
            </div>
            <div className="profilContainerRowSeperator"></div>
            <div className="profilContainerBody">
                <div className="profilContainerInfo">
                    <div className="profilContainerInfoColumn">
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Vorname:</div>
                            <div className="profilContainerRowValue">{profil.Vorname}</div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Nachname:</div>
                            <div className="profilContainerRowValue">{profil.Nachname}</div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Rolle:</div>
                            <div className="profilContainerRowValue">{profil.isAdmin === 0 ? "Mitarbeiter" : "Verwalter"}</div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Email:</div>
                            <div className="profilContainerRowValue">{profil.Email}</div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Telefonnummer:</div>
                            <div className="profilContainerRowValue">{profil.Tel}</div>
                        </div>
                    </div>
                    <div className="profilContainerColumnSeperator"></div>
                    <div className="profilContainerInfoColumn">
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Max. Stunden:</div>
                            <div className="profilContainerRowValue">{profil.MaxHrs}</div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Urlaubstage:</div>
                            <div className="profilContainerRowValue">{profil.Urlaubstage}</div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Praxis:</div>
                            <div className="profilContainerRowValue">{standort ? standort.Stadt : ""}, {standort ? standort.Str : ""}</div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Verfügbarkeit:</div>
                            <div className="profilContainerRowValue" style={{color: colorVar}}>{profil.Verfügbarkeit}</div>
                        </div>
                    </div>
                </div>
                <div className="profilContainerStats">
                    <div className="profilContainterStatsBox">
                        <div className="statDone"></div>
                        <div className="statSick"></div>
                        <div className="stat"></div>
                    </div>
                </div>
            </div>
        </div>
    )

}
import Moment from 'moment'
import React, { useState, useEffect } from "react"
import Axios from 'axios'
import "./calendarstyle.css"
import "./calendarPrint.css"
import CalendarEdit from './CalendarEdit'
import CalendarDayWrapper from './CalendarDayWrapper'
import CalendarPrompt from './CalendarPrompt'
import CalendarAdd from './CalendarAdd'
import moment from 'moment'

export default function CalendarBox(props) {
    //Zustände für die Prompt-, Add- und Edit-Boxen
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    //Infos für die ausgewählte Stunde
    const [givenTermin, setGivenTermin] = useState([]);
    const [givenMitarbeiter, setGivenMitarbeiter] = useState([]);
    const [currentSlot, setCurrentSlot] = useState("");
    const [currentStunde, setCurrentStunde] = useState("");
    const [terminListe, setTerminListe] = useState({});
    const [currTag, setCurrTag] = useState([]);
    const [mitarbeiterList, setMitarbeiterList] = useState([]);
    const [currentPatient, setCurrentPatient] = useState([]);
    useEffect(() => {
        //console.log("What I send" + props.currentWeek.format('YYYY-MM-DD').toString() + " Praxis: " + props.praxis)
        Axios.get('https://erlomed-kalender.de:3001/api/get/termineWeek', {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                Datum: props.currentWeek,
                Praxis: props.praxis
            }
        }).then((response) => {
           // console.log("response:" + JSON.stringify(response.data, null, 2));
            const processedTerminListe = processTerminListe(response.data);
            //console.log(JSON.stringify(processedTerminListe, null, 2));
            setTerminListe(processedTerminListe);
            
        }).catch((error) => {
            // Handle any errors that occur during the request
            console.error(error);
            //setLoadingFlag(false)
        });
        Axios.get('https://erlomed-kalender.de:3001/api/get/mitarbeiter', {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                Praxis: props.praxis
            }
        }).then((response) => {
            const mitarbeiterData = response.data;
            // Transform the data into a dictionary
            const mitarbeiterDictionary = mitarbeiterData.reduce((acc, mitarbeiter) => {
                const key = `${mitarbeiter.MitarbeiterID} ${mitarbeiter.Vorname} ${mitarbeiter.Nachname}`;
                acc[key] = mitarbeiter;
                return acc;
            }, {});
            setMitarbeiterList(mitarbeiterDictionary);
            //console.log('Mitarbeiter Dictionary:', mitarbeiterDictionary);
        }).catch((error) => {
            console.error("Fehler beim Laden der Mitarbeiter", error);
        });
    }, [props.currentWeek, props.praxis]);

    const processTerminListe = (terminListe) => {
        const processedTerminListe = {};

        terminListe.forEach(termin => {
            const { TerminTag, MitarbeiterID, TerminStunde, TerminSlot, TerminDauer } = termin;
            const date = moment(TerminTag).format("DD.MM.YYYY").toString();
            if (!processedTerminListe[date]) processedTerminListe[date] = {};
            if (!processedTerminListe[date][MitarbeiterID]) processedTerminListe[date][MitarbeiterID] = [];

            // Calculate slots
            const slots = calculateSlots(TerminStunde, TerminSlot, TerminDauer);

            // Add termin with slots
            processedTerminListe[date][MitarbeiterID].push({ ...termin, slots });
        });
        return processedTerminListe;
    };

    const calculateSlots = (hour, minuteSlot, duration) => {
        const slots = [];
        const startMinutes = hour * 60 + minuteSlot;
        const totalSlots = duration / 15;

        for (let i = 0; i < totalSlots; i++) {
            const currentSlot = startMinutes + i * 15;
            const hours = Math.floor(currentSlot / 60);
            const minutes = currentSlot % 60;
            slots.push(`${hours}:${minutes === 0 ? '00' : minutes}`);
        }

        return slots;
    };

    const fetchUpdatedTermine = () => {
        console.log("Fetching termine");
        Axios.get('https://erlomed-kalender.de:3001/api/get/termineWeek', {
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                Datum: props.currentWeek,
                Praxis: props.praxis
            }
        }).then((response) => {
            const processedTerminListe = processTerminListe(response.data);
            
            setTerminListe(processedTerminListe);
        }).catch((error) => {
            console.error("Fehler beim Laden der Termine", error);
        });
    };


    const days = []; // Array für die Tage
    //Toggle die Prompt-Box
    const showPrompt = (termin, mitarbeiter, currentStunde, currentSlot , patient) => {
        //console.log("Das was im showPrompt ankommt: " + JSON.stringify(termin, null, 2), JSON.stringify(mitarbeiter, null, 2), currentStunde, currentSlot)
        setGivenMitarbeiter(mitarbeiter);
        setGivenTermin(termin);
        setCurrentSlot(currentSlot);
        setCurrentStunde(currentStunde);
        setCurrentPatient(patient);
        //console.log("Date in showPrompt: Patient: " + JSON.stringify(termin, null, 2) + " Mitarbeiter: " + JSON.stringify(mitarbeiter, null, 2) + " CurrentStunde: " + currentStunde + " CurrentSlot " + currentSlot + " Patient: " + patient);
        setShow(!show);
    }

    //Toggle die Edit-Box
    const toggleEdit = (termin, mitarbeiter, stunde, slot, patient) => {
        // if (termin === "deleted") {
        //     setGivenMitarbeiter([]);
        //     setGivenTermin([]);
        //     setCurrentStunde([]);
        //     setCurrentSlot([]);
        // } else if (termin) {
        //     showPrompt(termin, mitarbeiter, stunde, slot, patient);
        // } else {
        //     showPrompt(givenTermin, givenMitarbeiter, currentStunde, currentSlot, patient)
        // }
        showPrompt(givenTermin, givenMitarbeiter, currentStunde, currentSlot, currentPatient)
        setShowEdit(!showEdit);
    }

    const toggleEditEdited = (termin, mitarbeiter, stunde, slot, patient) => {
        //Filter through mitarbeiterList via MitarbeiterID, because mitarbeiter is the ID of a Mitarbeiter
        // Gather the name of the mitarbeiter and store it inside a dictionary with mitarbeiterID and name as keys
        let mitarbeiterNameList = {
            name: "",
            mitarbeiterID: ""
        };
        const keys = Object.keys(mitarbeiterList).find(key => key.startsWith(`${mitarbeiter}`));
        console.log("Keys: " + keys);
        mitarbeiterNameList.name = mitarbeiterList[keys].Vorname + " " + mitarbeiterList[keys].Nachname;
        console.log("mitarbeiterNameList: " + JSON.stringify(mitarbeiterNameList, null, 2));
        mitarbeiterNameList.mitarbeiterID = mitarbeiter;
        showPrompt(termin, mitarbeiterNameList, stunde, slot, patient);
        setShowEdit(!showEdit);
    }

    const toggleEditDeleted = () => {
        setGivenMitarbeiter([]);
        setGivenTermin([]);
        setCurrentStunde([]);
        setCurrentSlot([]);
        setShowEdit(!showEdit);
    }


    //Toggle die Add-Box
    const toggleAdd = (tag, mitarbeiter, currentStunde, currentSlot) => {
        setCurrTag(tag);
        setCurrentStunde(currentStunde)
        setCurrentSlot(currentSlot)
        setGivenMitarbeiter(mitarbeiter)
        setShowAdd(!showAdd);
    }

    //Render die gesamte Woche
    if(terminListe === undefined || terminListe === null || terminListe.length === 0) {
        
    }else{

   for (let i = 0; i <= 4; i++) {
        const day = Moment(props.currentWeek.startOf('week').add(i, 'days')); // Moment-Objekt für den aktuellen Tag
        const date = day.format('DD.MM.YYYY').toString(); // Formatieren des Datums
        
        
        // // Debugging: Log the current date and the structure of terminListe
        const filteredTermine = terminListe[date] ? terminListe[date] : []; // Get appointments for the date
       //console.log("Filtered Termine for date:", date, JSON.stringify(filteredTermine, null, 2));
        
            days.push(
                <CalendarDayWrapper
                    typListe={props.typListe}
                    praxis={props.praxis}
                    key={i}
                    dayName={day.format('dddd')}
                    date={date}
                    setPrompt={showPrompt}
                    toggleAdd={toggleAdd}
                    termineTag={filteredTermine}
                    mitarbeiterNameList={mitarbeiterList}
                />
            );
        
        ////console.log("Übergeben" + JSON.stringify(filteredTermine, null, 2))
       
    }
    
}

    
        return (
            <div
                className="calendarBox"
                style={{
                    '@media print': {
                        // Add styles specific to printing mode
                        // For example:
                        marginTop: '0',
                        // Add any other styles you want for printing mode
                    }
                }}
            >
                {days}
                {showEdit && (
                    <CalendarEdit
                        terminListe={terminListe}
                        setTerminListe={setTerminListe}
                        typListe={props.typListe}
                        praxis={props.praxis}
                        currentSlot={currentSlot}
                        toggleEdit={toggleEdit}
                        currentStunde={currentStunde}
                        termin={givenTermin}
                        mitarbeiter={givenMitarbeiter}
                        togglePrompt={showPrompt}
                        fetchUpdatedTermine={fetchUpdatedTermine}
                        toggleEditDeleted={toggleEditDeleted}
                        toggleEditEdited={toggleEditEdited}
                    />
                )}
                {show && (
                    <CalendarPrompt
                        terminListe={terminListe}
                        setTerminListe={setTerminListe}
                        typListe={props.typListe}
                        praxis={props.praxis}
                        currentSlot={currentSlot}
                        toggleEdit={() => toggleEdit(givenTermin, givenMitarbeiter, currentStunde, currentSlot)}
                        currentStunde={currentStunde}
                        termin={givenTermin}
                        mitarbeiter={givenMitarbeiter}
                        setPrompt={showPrompt}
                    />
                )}
                {showAdd && (
                    <CalendarAdd
                        terminListe={terminListe}
                        setTerminListe={setTerminListe}
                        typListe={props.typListe}
                        praxis={props.praxis}
                        tag={currTag}
                        stunde={currentStunde}
                        minute={currentSlot}
                        toggleShow={showPrompt}
                        toggleAdd={toggleAdd}
                        mitarbeiter={givenMitarbeiter}
                        fetchUpdatedTermine={fetchUpdatedTermine}
                    />
                )}
                {//(show || showEdit) && <div className="blurr"></div>
                }
            </div>
        )
    
    
}

import "./mitarbeiterStyle.css"
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import Rezept from '../rezepte/Rezepte';
import moment from 'moment';
import MitarbeiterEdit from "./MitarbeiterEdit";
import MitarbeiterAdd from "./MitarbeiterAdd";

export default function Mitarbeiter() {

    const [mitarbeiterList, setMitarbeiterList] = useState([]);
    const [currMitarbeiter, setCurrMitarbeiter] = useState([]);
    const [prompt, setPrompt] = useState([]);
    const [showEdit, setEdit] = useState(false);
    const [showAdd, setAdd] = useState(false);
    const [showRecipe, setRecipe] = useState(false);
    const [showRecipeEdit, setRecipeEdit] = useState(false);
    const [showBox, setShow] = useState(false);
    

    const handleToggle = (newMitarbeiter) => {
        //console.log("Info Box wird getoggelt handleToggle");
        setCurrMitarbeiter(newMitarbeiter);
        setShow(!showBox);
        //console.log("Mitarbeiter wird gesetzt: " + JSON.stringify(newMitarbeiter, null, 2))
    }

    const handleEditToggle = (newMitarbeiter) => {
        //console.log("handleEditToggle" + JSON.stringify(currMitarbeiter, null, 2))
        setEdit(!showEdit);
        handleToggle(newMitarbeiter);
        //console.log("Mitarbeiter wird übergeben: " + JSON.stringify(newMitarbeiter, null, 2))
    }

    const handleAddToggle = (info) => {
        setAdd(!showAdd);
        if(info){
            handleToggle(info);
        }
    }
   

    const handleRecipeToggle = () => {
        handleToggle(currMitarbeiter);
        setRecipe(!showRecipe);
    }

    const handleRecipeEditToggle = () => {
        handleToggle(currMitarbeiter);
        setRecipeEdit(!showRecipeEdit);
    }

    

    useEffect(() => {
        Axios.get('https://erlomed-kalender.de:3001/api/get/mitarbeiter').then((response) => {
            setMitarbeiterList(response.data)
        })
    }, [currMitarbeiter]);


    return (
        <div className="mitarbeiterWrapper">
            <div className="mitarbeiterWrapperTitel">
                <div className="mitarbeiterWrapperIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                    </svg>

                    <h1>Mitarbeiter</h1>
                </div>
                <button className="mitarbeiterAddButton" onClick={() => handleAddToggle()}>
                    <span>Hinzufügen</span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </button>
            </div>
            {mitarbeiterList.map((val, keys) => {
                return (
                    <MitarbeiterBox key={keys} mitarbeiter={val} show={() => handleToggle(val)} />
                )

            })}
            {(showBox) && <MitarbeiterInfo mitarbeiter={currMitarbeiter} showEdit={handleEditToggle} show={() => handleToggle()} />}
            {(showEdit) && <MitarbeiterEdit mitarbeiter={currMitarbeiter} showEdit={handleEditToggle} updateMitarbeiter={setCurrMitarbeiter}/>}
            {(showAdd) && <MitarbeiterAdd showAdd={handleAddToggle} show={() => handleToggle()} />}

        </div>
    )
}

function MitarbeiterBox(props) {
    const pickColor = (val)=>{
        switch (val) {
            case 'Verfügbar':
                return 'hsl(120, 80%, 40%)';
            case 'Krank':
                return 'hsl(40, 80%, 50%)';
            case 'Urlaub':
                return 'hsl(312, 100%, 50%)';
            default:
                return 'hsl(0, 0%, 100%)';
        }
    }
    const colorVar = pickColor(props.mitarbeiter.Verfügbarkeit);

    return (
        <div className="mitarbeiterBox" onClick={() => props.show()}>
            <div className="mitarbeiterBoxName">
                {props.mitarbeiter.Vorname} {props.mitarbeiter.Nachname}
            </div>

            <div className="mitarbeiterBoxBday" style={{color: colorVar}}>{props.mitarbeiter.Verfügbarkeit}</div>
        </div>
    )
}

function MitarbeiterInfo(props) {
    const [closing, setClosing] = useState(false);
    const [newMitarbeiter, setNewMitarbeiter] = useState(props.mitarbeiter);
    const [showRecipe, setShowRecipe] = useState(false);
    const [currPraxis, setCurrPraxis] = useState([])
    const [krankeTage, setKrankeTage] = useState(0)
    const [availUpload, setAvailUpload] = useState(props.mitarbeiter.Verfügbarkeit);
    //console.log("info mounted with: " + JSON.stringify(krankeTage, null, 2));
    const onClose = () => {
        setClosing(true);
        setTimeout(() => {
            props.show(props.mitarbeiter);
        }, 650);
    }

    const onEdit = () => {
        setClosing(true);
        setTimeout(() => {
            //console.log("onEdit " + newMitarbeiter);
            props.showEdit(newMitarbeiter);
        }, 650);
    }

    const onShowRecipe = () => {
        setClosing(!closing);
        setTimeout(() => {
            setShowRecipe(!showRecipe);
        }, 650);
    }

    const onAddRecipe = () => {
        setClosing(true);
        setTimeout(() => {
            props.showEdit();
        }, 650);
    }

    useEffect(() => {
        Axios.get("https://erlomed-kalender.de:3001/api/get/praxis", {
            params : {
                ID: props.mitarbeiter.Standort
            }
        }).then((response) => {
            setCurrPraxis(response.data)
        }).catch((error) => {
            //console.log(error);
        });
        Axios.get("https://erlomed-kalender.de:3001/api/get/krankeTage", {
            params : {
                ID: props.mitarbeiter.MitarbeiterID
            }
        }).then((response) => {
            setKrankeTage(response.data)
        }).catch((error) => {
            //console.log(error);
        });
    }, [props.mitarbeiter])

   

    return (
        <>
            {showRecipe && <Rezept handleClose={() => onShowRecipe()} mitarbeiter={props.mitarbeiter} />}
            <div className={`blurrB ${closing ? "closing" : ""}`}></div>
            <div className={`mitarbeiterInfo ${closing ? "closing" : ""}`} >
                <div className="mitarbeiterInfoHeader">
                    <h2>{props.mitarbeiter.Vorname} {props.mitarbeiter.Nachname}</h2>
                    <button className="closeButton" onClick={onClose}>X</button>
                </div>
                {/*<div className="mitarbeiterRowSeperator" style={{borderColor: "black", border: "2px solid"}}/>*/}
                <div className="mitarbeiterInfoContent">
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Vorname:</div>
                        <div className="mitarbeiterInfoValue">{props.mitarbeiter.Vorname}</div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Nachname:</div>
                        <div className="mitarbeiterInfoValue">{props.mitarbeiter.Nachname}</div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Verfügbarkeit:</div>
                        <div className="mitarbeiterInfoValue" style={{cursor: "pointer"}}>{props.mitarbeiter.Verfügbarkeit}</div>
                    </div>
                    <div className="mitarbeiterRowSeperator" />
                    <div className="mitarbeiterInfoKrankenkasse">
                        <h4>Praxisinformationen</h4>
                        <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Beruf:</div>
                        <div className="mitarbeiterInfoValue">{props.mitarbeiter.Beruf}</div>
                    </div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Standort:</div>
                        <div className="mitarbeiterInfoValue">{currPraxis[0] ? (<>{currPraxis[0].Stadt}, {currPraxis[0].Str} {currPraxis[0].Hnr}</>) : null}</div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Stunden:</div>
                        <div className="mitarbeiterInfoValue">{props.mitarbeiter.MaxHrs}</div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Urlaubstage:</div>
                        <div className="mitarbeiterInfoValue">{props.mitarbeiter.Urlaubstage}</div>
                    </div>
                    <div className="mitarbeiterInfoRow">
                        <div className="mitarbeiterInfoLabel">Stunden in denen {props.mitarbeiter.Vorname} {props.mitarbeiter.Nachname} krank war:</div>
                        <div className="mitarbeiterInfoValue">{krankeTage[0]? krankeTage[0].count : 0}</div>
                    </div>
                    <div className="mitarbeiterRowSeperator" />
                    <div className="mitarbeiterInfoKontaktdaten">
                        <h4>Kontaktdaten</h4>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">E-mail:</div>
                            <div className="mitarbeiterInfoValue">{props.mitarbeiter.Email}</div>
                        </div>
                        <div className="mitarbeiterInfoRow">
                            <div className="mitarbeiterInfoLabel">Telefonnummer:</div>
                            <div className="mitarbeiterInfoValue">{props.mitarbeiter.Tel}</div>
                        </div>
                    </div>
                </div>
                <div className="mitarbeiterRowSeperator" />
                {//<div className="mitarbeiterInfoTextArea">

                    //<textarea placeholder="Notizen zum Mitarbeiteren:"></textarea>
                    // </div>
                }
                <div className="mitarbeiterInfoFooter">
                    <button className="mitarbeiterInfoButton" onClick={() => onEdit()}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z" />
                        </svg>
                        <span>Bearbeiten</span>
                    </button>
                </div>
            </div>
        </>
    )

}




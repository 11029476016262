import "./homestyle.css"
import { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment"
import { PreviewTwoTone } from "@mui/icons-material";
export default function Issue({issueObj, setIssueList, issueList}){
    const [currUser, setCurrUser] = useState([])
    const [editMode, setEditMode] = useState(false)
    const [upload, setUpload] = useState(issueObj)
    //console.log("IssueObj = " + JSON.stringify(upload, null, 2))
    useEffect(()=>{
        Axios.get("https://erlomed-kalender.de:3001/api/get/getMitarbeiter", {
            params: {
                ID: issueObj.IssueUser
            }
        }).then(response => {
            ////console.log(response.data)
            setCurrUser(response.data[0])
        }).catch(error => {
            console.error("Fehler beim aufrufen des Users " + error)
        })
    }, [])

    function deleteIssue(){
        Axios.delete('https://erlomed-kalender.de:3001/api/delete/issue', {
            params: {
                ID: issueObj.IssueID
            }
        }).then(response => {
                ////console.log('Spalte erfolgreich gelöscht');
                setIssueList(issueList.filter((issue) => issue.IssueID !== issueObj.IssueID));
            })
            .catch(error => {
                console.error('Fehler beim Löschen der Spalte:', error);
            });
    }

    function editIssue(){
        Axios.post("http://localhsot:3001/api/post/issueEdit", upload)
        .then(response => {
            console.log(response);
        }).catch(error => {
            console.error(error)
        })
    }

    function handleChange(event){
        const {name, value} = event.target;
        setUpload(prevData => ({
            ...prevData,
            [name] : value 
        }))
    }

    if(!editMode) {return (
        <div className="issueWrapper">
        <div className="issueHeader">
        <div>{moment(issueObj.IssueDate).format("DD.MM.YYYY")}</div>
        <div>{currUser.Vorname}</div>
        <div className="buttonWrapper">
        <button className="issueDelete" onClick={() => deleteIssue()}>Löschen</button>
        <button className="issueEdit" onClick={() => setEditMode(true)}>Bearbeiten</button>
        </div>
        </div>
        <div className="issueSeperator"></div>
            <div className="issueText">
            {issueObj.IssueText}
            </div>
        </div>
    )}else{
        return(
            <div className="issueWrapper">
        <div className="issueHeader">
        <div>{moment(issueObj.IssueDate).format("DD.MM.YYYY")}</div>
        <div>{currUser.Vorname}</div>
        <button className="issueDelete" onClick={() => deleteIssue()}>Löschen X</button>
        </div>
        <div className="issueSeperator"></div>
            <div className="issueText">
            <input type="text" name="IssueText" value={issueObj.IssueText} onChange={handleChange}></input>
            </div>
        </div>
        )
    }
}
import { useState } from "react"
import "./homestyle.css"
import IssueBox from "./IssueBox";
import IssueAdd from "./IssueAdd";

export default function Home() {
    const [addMode, setAddMode] = useState(false);

    function toggleAdd(){
        setAddMode(!addMode);
    }

    

    return (
        <div className="home">
        {addMode && <IssueAdd toggle={toggleAdd}></IssueAdd>}
            <IssueBox toggle={toggleAdd}/>
            
        </div>
    )
}

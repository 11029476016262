import "./navstyle.css"
import { Link, useMatch, useResolvedPath } from "react-router-dom"
import axios from "axios"
import { useEffect, useState } from "react"
export default function Navbar(props) {
    const [isAdmin, setIsAdmin] = useState(false);
    const logout = ()=> {
        axios.get('https://erlomed-kalender.de:3001/api/auth/logout', {
        }).then((response) => {
            ////console.log("Termineliste geladen! : " + JSON.stringify(response.data, null, 2))
            props.logout()
            localStorage.removeItem('access-token');
        }).catch((error) => {
            // Handle any errors that occur during the request
            console.error(error + " Benutzer ist nicht angemeldet!");
            // ...
          });
    }

    useEffect(()=> {
        axios.get('https://erlomed-kalender.de:3001/api/auth/getCurrUser')
          .then(response =>{
            if(response.data.isAdmin === 1){
                setIsAdmin(true);
              }else{
                setIsAdmin(false);
              }
          })
          .catch(error =>{
            setIsAdmin(false);
            console.error(error);
          })
    })

    return (
        <nav className="nav">
            <Link to="/home" className="nav-logo">
                erlomed
            </Link>
            <ul className="navContent">
                <CostumLink to="/home">Home</CostumLink>
                <CostumLink to="/calendar">Kalender</CostumLink>
                 <CostumLink to="/patient">Patienten</CostumLink>
                 {isAdmin&&<CostumLink to="/mitarbeiter">Mitarbeiter</CostumLink>}
                 {isAdmin&&<CostumLink to="/kürzel">Therapien</CostumLink>}
                <CostumLink to="/profil">Profil</CostumLink>
            </ul>
            <ul className="logOff">
            
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                </svg>
                <li onClick={logout}>Abmelden</li>
            </ul>
        </nav>
    )
}

function CostumLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>
                {children}
            </Link>
        </li>
    )
}
import "./patientStyle.css"
import React, { useState, useEffect } from "react";
import Axios from 'axios';
import Rezept from '../rezepte/Rezepte';
import moment from 'moment';
import PatientInfo from "./PatientInfo";
import PatientEdit from "./PatientEdit";
import PatientAdd from "./PatientAdd";

export default function Patient() {

    const [patientList, setPatientList] = useState([]);
    const [currPatient, setCurrPatient] = useState([]);
    const [showEdit, setEdit] = useState(false);
    const [showAdd, setAdd] = useState(false);
    const [showRecipe, setRecipe] = useState(false);
    const [showRecipeEdit, setRecipeEdit] = useState(false);
    const [showBox, setShow] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(patientList);
    //console.log("CurrPatient" + JSON.stringify(currPatient, null, 2))
    const handleToggle = (newPatient) => {
        ////console.log("Info Box wird getoggelt handleToggle");
        setCurrPatient(newPatient);
        setShow(!showBox);
        ////console.log("Patient wird gesetzt: " + JSON.stringify(newPatient, null, 2))
    }

    const handleEditToggle = (newPatient) => {
        // //console.log("handleEditToggle" + JSON.stringify(currPatient, null, 2))
        setEdit(!showEdit);
        setShow(!showBox)
        ////console.log("Patient wird übergeben: " + JSON.stringify(newPatient, null, 2))
    }

    const handleAddToggle = (info) => {
        setAdd(!showAdd);
        if (info) {
            handleToggle(info);
        }
    }
    useEffect(() => {
        Axios.get('https://erlomed-kalender.de:3001/api/get/patienten').then((response) => {
            setPatientList(response.data);
            setFilteredOptions(response.data);
        })
    }, [currPatient, showEdit, showAdd, showBox]);

    const handleSearchInput = (event) => {
        const input = event.target.value;
        setSearchInput(input);

        const filtered = patientList.filter((patient) =>
            `${patient.Vorname} ${patient.Nachname}`.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredOptions(filtered);
    };

    return (
        <div className="patientWrapper">
            <div className="patientWrapperTitel">
                <div className="patientWrapperIcon">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                    </svg>

                    <h1>Patienten</h1>
                </div>
                <div className="patientSearch">
                    <input type="text" onChange={handleSearchInput} placeholder="Suche..."></input>
                </div>
                <div className="patientAddButtonWrapper">
                    <button className="patientAddButton" onClick={() => handleAddToggle()}>
                        <span>Hinzufügen</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                    </button>
                </div>
            </div>
            {filteredOptions.map((val, keys) => {
                return (
                    <PatientBox key={keys} patient={val} show={() => handleToggle(val)} />
                )

            })}
            {(showBox) && <PatientInfo patient={currPatient} showEdit={handleEditToggle} show={() => handleToggle()} />}
            {(showEdit) && <PatientEdit patient={currPatient} closeEdit={()=>{
                setEdit(false);
                setShow(true);
            }} handleDelPatient={()=>{
                setEdit(false);
                setShow(false);
            }} updatePatient={setCurrPatient} />}
            {(showAdd) && <PatientAdd closeAdd={()=> {
                setAdd(false);
                setShow(true);
                }} updatePatient={setCurrPatient} />}
        </div>
    )
}

function PatientBox(props) {

    return (
        <div className="patientBox" onClick={() => props.show()}>
            <div className="patientBoxName">
                {props.patient.Vorname} {props.patient.Nachname}
            </div>

            <div className="patientBoxBday">{props.patient.Geburtstag}</div>
        </div>
    )
}








import { useEffect, useState } from "react"
import Axios from "axios";
import "./profilStyle.css"
export default function ProfilEdit({ profil, standort, toggle, parentSubmit }) {
    const [uploadData, setUploadData] = useState(profil);
    
    function closeWidget() {
        ////console.log("Wird getoggelt")
        toggle(false);
    }
    
    function childSubmit(){
        parentSubmit(uploadData);
        closeWidget();
    }

    function handleChange(event) {
        const { name, value } = event.target;
        setUploadData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }
    
    const pickColor = (val)=>{
        switch (val) {
            case 'Verfügbar':
                return 'hsl(120, 80%, 50%)';
            case 'Krank':
                return 'hsl(40, 80%, 70%)';
            case 'Urlaub':
                return 'hsl(195, 100%, 72%)';
            default:
                return 'hsl(0, 0%, 100%)';
        }
    }
    return (
        <div className="profilContainer">
            <div className="profilContainerHeader">
                <h1 style={{ width: "100%" }}>{profil.Vorname} {profil.Nachname}</h1>
                <div className="profilContainerButtons">
                    <button className="profilSubmitButton" onClick={childSubmit}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                    </button>
                    <button className="profilCloseButton" onClick={closeWidget}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="profilContainerRowSeperator"></div>
            <div className="profilContainerBody">
                <div className="profilContainerInfo">
                    <div className="profilContainerInfoColumn">
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Vorname:</div>
                            <div className="profilContainerRowValue">
                                <input name="Vorname" placeholder={uploadData.Vorname} type="text" onChange={handleChange}></input>
                            </div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Nachname:</div>
                            <div className="profilContainerRowValue">
                                <input name="Nachname" placeholder={uploadData.Nachname} type="text" onChange={handleChange}></input>
                            </div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Beruf:</div>
                            <div className="profilContainerRowValue">
                                <input name="Beruf" placeholder={uploadData.Beruf} type="text" onChange={handleChange}></input>
                            </div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Email:</div>
                            <div className="profilContainerRowValue">
                                <input name="Email" placeholder={uploadData.Email} type="text" onChange={handleChange}></input>
                            </div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Telefonnummer:</div>
                            <div className="profilContainerRowValue">
                                <input name="Tel" placeholder={uploadData.Tel} type="text" onChange={handleChange}></input>
                            </div>
                        </div>
                    </div>
                    <div className="profilContainerColumnSeperator"></div>
                    <div className="profilContainerInfoColumn">
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Praxis:</div>
                            <div className="profilContainerRowValue">{standort ? standort.Stadt : ""}, {standort ? standort.Str : ""}
                            </div>
                        </div>
                        <div className="profilContainerRow">
                            <div className="profilContainerRowLable">Verfügbarkeit:</div>
                            <div className="profilContainerRowValue">
                                <select name="Verfügbarkeit" value={uploadData.Verfügbarkeit} onChange={handleChange} style={{color : pickColor(uploadData.Verfügbarkeit)}}>
                                    <option value="Verfügbar" style={{color : pickColor("Verfügbar")}}>Verfügbar</option>
                                    <option value="Krank" style={{color : pickColor("Krank")}}>Krank</option>
                                    <option value="Urlaub" style={{color : pickColor("Urlaub")}}>Urlaub</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profilContainerStats">
                    <div className="profilContainterStatsBox">
                        <div className="statDone"></div>
                        <div className="statSick"></div>
                        <div className="stat"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}